import { Flex } from '@chakra-ui/react';
import React from 'react';

import { Button, FadeUp, H3, Paragraph } from 'DesignLibrary/atoms';
import { PicwellScore } from 'DesignLibrary/atoms/PicwellScore';
import { SelectedBadge } from 'DesignLibrary/atoms/SelectedBadge';
import { useViewport } from 'DesignLibrary/context';
import Plan from 'Models/plan';
import Text from 'Shared/Text';

import { Divider, Head, PlanTitle, SearchProviderWrapper } from './styled';

export interface ValuesProps {
  plan: Plan;
  isSelected: boolean;
  hidePlanScore: boolean;
  searchProviderLink: string;
  isImprovedRecExplanationEnabled: boolean;
}

export const CardHeader = ({
  plan,
  hidePlanScore,
  isSelected,
  searchProviderLink,
  isImprovedRecExplanationEnabled,
}: ValuesProps) => {
  const { device } = useViewport();

  // Plan Constants
  const score = plan?.picwellScore || 0;
  const tier = plan?.tier || 'green';
  const plan_marketing_name = plan?.planMarketingName || 'MISSING PLAN MARKETING NAME';

  return (
    <div>
      <div className="score-container-attached">
        <FadeUp
          translateX="1rem"
          translateY="0"
          timeout={750}
          delay={2000}
          isOpen={device === 'desktop' && !isSelected && !hidePlanScore && !isImprovedRecExplanationEnabled}
          enableTransitionInJest
        >
          <PicwellScore dataTestId="legacy-score-desktop" size="large" score={score} tier={tier} attached />
        </FadeUp>
      </div>
      <div className="badge-container">
        <div className="badge">
          <FadeUp translateX="1rem" translateY="0" timeout={500} isOpen={device === 'desktop' && isSelected}>
            <SelectedBadge />
          </FadeUp>
        </div>
      </div>
      <Head>
        {(!isImprovedRecExplanationEnabled || isSelected) && (
          <div className="score-container">
            <FadeUp isOpen={device !== 'desktop' && !isSelected && !hidePlanScore} enableTransitionInJest>
              <PicwellScore
                dataTestId="legacy-score-mobile"
                size={device === 'mobile' ? 'medium' : 'large'}
                score={score}
                tier={tier}
              />
            </FadeUp>
            <FadeUp isOpen={device !== 'desktop' && isSelected}>
              <SelectedBadge sizeOverride={device === 'mobile' ? 'small' : 'large'} />
            </FadeUp>
          </div>
        )}
        <PlanTitle
          className={`header-content ${isSelected ? 'scoot' : null}`}
          length={plan_marketing_name.length}
        >
          <Flex align="center" gap={[2, 3]}>
            {plan?.carrierName && (
              <Paragraph lineHeight={1.35} size="small">
                {plan.carrierName}
              </Paragraph>
            )}
            {searchProviderLink && (
              <>
                <Divider />
                <SearchProviderWrapper>
                  <Button
                    url={searchProviderLink}
                    buttonType="link"
                    iconLeft="Binoculars"
                    iconProps={{
                      size: 20,
                    }}
                    size="xsmall"
                  >
                    <Text field="health_section.button_text.providerSearch" />
                  </Button>
                </SearchProviderWrapper>
              </>
            )}
          </Flex>

          <H3 as="h2" title={plan_marketing_name}>
            {plan_marketing_name}
          </H3>
        </PlanTitle>
      </Head>
    </div>
  );
};
