import { Box } from '@chakra-ui/react';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';

import { PlannedProceduresForm } from 'Containers/ProfilePage/MemberSection/HouseholdMember/PlannedProceduresForm';
import { AudioConfigTooltipContentfulPaths } from 'Containers/ProfilePage/ProfileAudio';
import {
  Member,
  MEMBER_TYPE,
  MemberSectionConfigFields,
  RxSearchResults,
  UpdateMemberFields,
} from 'Containers/ProfilePage/types';
import { MemberCard, ProfileAudio } from 'ContentfulDefaults/types/profile';
import { useTextContext } from 'Contexts/textContext';
import { H5, H6, Icon, Paragraph } from 'DesignLibrary/atoms';
import { AudioTooltip } from 'DesignLibrary/atoms/AudioTooltip';
import { Button } from 'DesignLibrary/atoms/Button';
import TextInput from 'DesignLibrary/atoms/inputs/TextInput';
import ToggleList from 'DesignLibrary/atoms/ToggleList';
import { Tooltip } from 'DesignLibrary/atoms/Tooltip';
import { TooltipCaps } from 'DesignLibrary/molecules';
import Text from 'Shared/Text';
import { Relationship } from 'Types/entities';
import { validateMemberAge } from 'Utils/validators';

import PrescriptionForm from './PrescriptionForm';
import {
  MemberEdit,
  MemberForms,
  PrescriptionContainer,
  QuestionContainer,
  QuestionHorizontalLine,
  UtilContainer,
} from './styled';
import UtilizationForm from './UtilizationForm';

export interface HouseholdMemberProps {
  member: Member;
  relationship: string;
  drugResults: RxSearchResults;
  memberConfig: MemberSectionConfigFields;
  isRxLoading: boolean;
  isRxSkipped: boolean;
  isProfileAudioEnabled: boolean;
  dependentIndex?: number;
  queryDrugs: (value: string) => void;
  handleEdit: (fields: UpdateMemberFields, memberConfig: MemberSectionConfigFields) => void;
  handleDelete: () => void;
  setError: (isError: boolean) => void;
}

export const HouseholdMember = ({
  member,
  relationship,
  memberConfig,
  drugResults,
  isRxLoading,
  isRxSkipped,
  isProfileAudioEnabled,
  dependentIndex = -1,
  queryDrugs,
  handleEdit,
  handleDelete,
  setError,
}: HouseholdMemberProps) => {
  const { retrieveContentfulData } = useTextContext();
  const { isComplete, isValid } = member;
  const {
    collectTobaccoUsageForPolicyholder,
    collectTobaccoUsageForSpouse,
    collectIsSpouseOrDomesticPartner,
    isProductBc,
    enablePlannedProcedures,
  } = memberConfig;

  const profileCard = retrieveContentfulData<MemberCard>('profile_section.member');

  const spouseLabel = retrieveContentfulData<string>('profile_section.member.spouse_label', 'Spouse');
  const domesticPartnerLabel = retrieveContentfulData<string>(
    'profile_section.member.domestic_partner_label',
    'Domestic Partner',
  );

  // Toggle list options ("Yes" or "No")
  const optionY = (profileCard?.button_options && profileCard?.button_options.yes) || 'Yes';
  const optionN = (profileCard?.button_options && profileCard?.button_options.no) || 'No';

  // initial value of null for usesTobacco, usesPrescriptions, usesUtilization, usesPlannedProcedures
  const [form, setForm] = useState({
    age: member.age,
    gender: member.gender,
    isLoading: false,
    usesTobacco: member.uses_tobacco,
    usesPrescriptions: member.uses_prescriptions,
    usesUtilization: member.uses_utilization,
    usesPlannedProcedures: member.uses_planned_procedures,
    // used to determine spouse/domestic partner for BC
    memberType: member.member_type,
  });
  const [ageBlurred, setAgeBlurred] = useState(false);

  const isError = !member.isComplete && !ageBlurred ? false : !validateMemberAge(form.age, relationship);

  useEffect(() => {
    // Only manually set focus on HouseholdMember when user clicks Add Member button
    // On first render we set focus if member form is not complete
    if (!isComplete) {
      if (relationship === 'policyholder') {
        // focus on sex tooltip (first interactive element) for policyholder
        document.getElementById(`${member.external_id}-sex-outer-tooltip-text`)?.focus();
      } else {
        // focus on delete button (first interactive element) for all other members
        document.getElementById(`${member.external_id}-delete`)?.focus();
      }

      if (
        relationship === 'spouse' &&
        !collectIsSpouseOrDomesticPartner &&
        form.memberType === MEMBER_TYPE.SPOUSE_OR_DOMESTIC_PARTNER
      ) {
        setForm({ ...form, memberType: MEMBER_TYPE.SPOUSE });
      }
    }
  }, []);

  useEffect(() => {
    setError(isError);
  }, [validateMemberAge, ageBlurred, member]);

  const handleGenderSelect = (gender: string) => {
    const newForm = {
      ...form,
      gender,
    };
    setForm(newForm);
    saveMember(newForm);
  };

  const handleMemberDelete = (e) => {
    e.stopPropagation();

    handleDelete();
  };

  // handle tobacco, prescription, utilization, planned procedures
  // These fields are values for boolean toggles
  const handleToggleInput = (e) => {
    const value = e.target.value;
    // Not having unique name attributes on Toggles seems to break tab-order, splitting off member id from name
    const name = e.target.name.split('-')[0];
    const newForm = {
      ...form,
      [name]: value === 'true',
    };
    setForm(newForm);
    saveMember(newForm);
  };

  const handleInputChange = (e) => {
    const target = e.target;
    const name = target.name;
    const value = target.value;
    const newForm = {
      ...form,
      [name]: value,
    };

    setForm(newForm);
    saveMember(newForm);
  };

  const saveMember = (newForm) => {
    // Trigger action to save the member in reducer
    const {
      gender,
      age,
      usesTobacco,
      usesPrescriptions,
      usesUtilization,
      usesPlannedProcedures,
      memberType,
    } = newForm;

    const fields = {
      age,
      gender,
      uses_tobacco: usesTobacco,
      uses_prescriptions: usesPrescriptions,
      uses_utilization: usesUtilization,
      uses_planned_procedures: usesPlannedProcedures,
      member_type: memberType,
      pristine: false,
    };

    handleEdit(fields, memberConfig);
  };

  const cardName = profileCard.name;
  const tooltipId = `tooltip-${_.kebabCase(cardName)}`;
  const audioTooltipId = `audio-${tooltipId}`;

  const getProfileAudioConfig = (path: AudioConfigTooltipContentfulPaths): ProfileAudio =>
    retrieveContentfulData<ProfileAudio>(path);

  const prescriptionAudioConfig: ProfileAudio = getProfileAudioConfig(
    AudioConfigTooltipContentfulPaths.PrescriptionDrugsAudioTooltip,
  );
  const utilAudioConfig: ProfileAudio = getProfileAudioConfig(
    AudioConfigTooltipContentfulPaths.UtilSectionAudioTooltip,
  );

  const getIsValidUtilization = () =>
    (member.uses_utilization && Object.values(member.utilization).some((i) => i > 0)) ||
    member.uses_utilization === false;

  const getIsValidPlannedProcedures = () =>
    (member.uses_planned_procedures && Object.values(member.planned_procedures).some((i) => i > 0)) ||
    member.uses_planned_procedures === false;

  function renderMemberText() {
    switch (relationship) {
      case 'policyholder':
        return <Text field="profile_section.member.policyholder_card_header" />;
      case 'spouse':
        return collectIsSpouseOrDomesticPartner ? (
          <Text field="profile_section.member.domestic_partner_card_header" />
        ) : (
          <Text field="profile_section.member.spouse_card_header" />
        );
      default:
        return <Text field="profile_section.member.dependent_card_header" />;
    }
  }

  const currentColor = () => {
    let color = 'blue';

    if (!isComplete && !isValid) color = 'yellow';
    if ((isComplete && !isValid) || isError) color = 'red';

    return color;
  };

  const renderHeader = () => {
    let removeButtonLabel = profileCard.accessibility[`remove_${relationship}`];

    if (relationship === 'dependent') {
      removeButtonLabel += ` ${dependentIndex + 1}`;
    }

    return (
      <section className={`member-header ${relationship}`}>
        <div className="title">
          <Icon type="User" color={`--colors-${currentColor()}-600`} />
          <H5 as="h3">{renderMemberText()}</H5>
        </div>
        <div>
          {relationship !== 'policyholder' && (
            <Button
              aria-label={removeButtonLabel}
              buttonType="standalone"
              iconLeft="Trash"
              iconProps={{
                color: `--colors-${currentColor()}-600`,
              }}
              data-testid={`${member.external_id}-delete`}
              id={`${member.external_id}-delete`}
              onClick={handleMemberDelete}
            />
          )}
        </div>
      </section>
    );
  };

  const isSexInputPreferNoAnswerEnabled = memberConfig.allow_sex_at_birth_no_answer_enabled;

  const renderSexInput = () => {
    if (!memberConfig.sex_at_birth_enabled) {
      return null;
    }

    const optionMale = (profileCard?.button_options && profileCard?.button_options.male) || 'Male';
    const optionFemale = (profileCard?.button_options && profileCard?.button_options.female) || 'Female';
    const optionPreferNotToSay = profileCard.button_options.prefer_not_to_say;

    const audioConfig: ProfileAudio = retrieveContentfulData<ProfileAudio>(
      AudioConfigTooltipContentfulPaths.SexAtBirthAudioTooltip,
    );

    const sexAtBirthLabel = profileCard.tooltips.sex.title;

    const sexOptions = [
      {
        option: optionMale,
        value: 'male',
      },
      {
        option: optionFemale,
        value: 'female',
      },
    ];

    if (isSexInputPreferNoAnswerEnabled) {
      sexOptions.push({
        option: optionPreferNotToSay,
        value: 'prefer_not_to_answer',
      });
    }

    return (
      <Box pos="relative" className="question sex-question question-wrapper">
        {isProfileAudioEnabled ? (
          <>
            <Box pos="absolute" top={0} left={-12}>
              <AudioTooltip
                data-testid="audio-tooltip-sex"
                urlToAudio={audioConfig.audio_file_url}
                urlToClosedCaptionVtt={audioConfig.vtt_transcript_file_url}
              />
            </Box>
            <Paragraph size="small">{sexAtBirthLabel}</Paragraph>
          </>
        ) : (
          <TooltipCaps
            color="--text-black"
            tooltip={profileCard.tooltips.sex.text}
            keyHint={`member-${member.external_id}-sex`}
          >
            {sexAtBirthLabel}
          </TooltipCaps>
        )}

        <div className="sex-toggle">
          <ToggleList
            label={profileCard.tooltips.sex.title}
            groupName={`genderSelect-${relationship}-${member.external_id}`}
            className="pingdom-gender-select"
            data-testid={`${member.external_id}-gender-input`}
            options={sexOptions}
            handleChange={(e) => handleGenderSelect(e.target.value)}
            color="--colors-gray-black"
            selected={form.gender}
            sizing="small"
            stretch
          />
        </div>
      </Box>
    );
  };

  const ageInputLabelId = `${member.external_id}-age-label`;

  const renderAgeInput = () => (
    <div className="question age-question question-wrapper">
      <H6 id={ageInputLabelId}>{profileCard.age_label}</H6>
      <TextInput
        labelId={ageInputLabelId}
        className="pingdom-age-input"
        data-testid={`${member.external_id}-age-input`}
        error={isError}
        // error={true}
        inputType="integer"
        type="tel"
        maxLength={3}
        pattern="[0-9]*"
        name="age"
        placeholder={relationship !== 'dependent' ? '25' : '5'}
        handleChange={handleInputChange}
        value={form.age}
        onBlur={() => setAgeBlurred(true)}
        stretch
      />
    </div>
  );

  const renderDomesticPartnerInput = () => {
    if (!collectIsSpouseOrDomesticPartner || !isProductBc || relationship !== 'spouse') {
      return null;
    }

    const domesticPartnerQuestionLabel = (
      <Text field="profile_section.member.domestic_partner_question_label" />
    );
    return (
      <QuestionContainer className="question-wrapper">
        <div className="question-container">
          <Paragraph size="small">
            {domesticPartnerQuestionLabel}
            <Tooltip
              id="domestic-partner-tooltip"
              size="small"
              label={profileCard.tooltips.domestic_partner}
            />
          </Paragraph>
        </div>
        <div className="toggle-container" data-testid={`${member.external_id}-domestic-partner-input`}>
          <ToggleList
            label={domesticPartnerQuestionLabel}
            groupName="domestic-partner"
            name="memberType"
            options={[
              {
                option: spouseLabel,
                value: MEMBER_TYPE.SPOUSE,
              },
              {
                option: domesticPartnerLabel,
                value: MEMBER_TYPE.DOMESTIC_PARTNER,
              },
            ]}
            handleChange={handleInputChange}
            color="--colors-gray-black"
            selected={member.member_type}
            sizing="small"
            stretch
          />
        </div>
      </QuestionContainer>
    );
  };

  const renderTobaccoInput = () => {
    if (
      (relationship === 'policyholder' && !collectTobaccoUsageForPolicyholder) ||
      (relationship === 'spouse' && !collectTobaccoUsageForSpouse) ||
      relationship === 'dependent'
    ) {
      return null;
    }

    const tobaccoLabel = (
      <Text
        field="profile_section.member.tobacco_header"
        vars={{
          x:
            relationship === 'policyholder'
              ? profileCard.pronoun_map.you.toLowerCase()
              : profileCard.pronoun_map.they.toLowerCase(),
        }}
      />
    );

    return (
      <QuestionContainer className="question-wrapper">
        <div className="question-container">
          <Paragraph size="small">
            {tobaccoLabel}
            <Tooltip id="tobacco-tooltip" size="small" label={profileCard.tooltips.tobacco} />
          </Paragraph>
        </div>
        <div className="toggle-container" data-testid={`${member.external_id}-tobacco-input`}>
          <ToggleList
            label={tobaccoLabel}
            groupName={`tobacco-${relationship}-${member.external_id}`}
            className="pingdom-uses-tobacco"
            name={`usesTobacco-${member.external_id}`}
            options={[
              {
                option: optionY,
                value: 'true',
              },
              {
                option: optionN,
                value: 'false',
              },
            ]}
            handleChange={handleToggleInput}
            color="--colors-gray-black"
            selected={member.uses_tobacco?.toString()}
            sizing="small"
            stretch
          />
        </div>
      </QuestionContainer>
    );
  };

  const renderTobaccoPrescriptionLine = () => {
    const hasTobaccoQuestion =
      (relationship === 'policyholder' && collectTobaccoUsageForPolicyholder) ||
      (relationship === 'spouse' && collectTobaccoUsageForSpouse);

    const hasDomesticPartnerQuestion = collectIsSpouseOrDomesticPartner && relationship === 'spouse';

    if (!hasTobaccoQuestion && !hasDomesticPartnerQuestion) {
      return null;
    }

    if (member.uses_prescriptions) {
      return <QuestionHorizontalLine />;
    }

    return null;
  };

  const renderPrescriptionInput = () => {
    const prescriptionLabel = (
      <Text
        field="profile_section.member.prescription_header"
        vars={{
          x:
            relationship === 'policyholder'
              ? profileCard.pronoun_map.you.toLowerCase()
              : profileCard.pronoun_map.they.toLowerCase(),
        }}
      />
    );

    return (
      <Box pos="relative">
        {isProfileAudioEnabled && (
          <Box pos="absolute" top={member.uses_prescriptions ? -2 : 0} left={-12}>
            <AudioTooltip
              data-testid={audioTooltipId}
              urlToAudio={prescriptionAudioConfig.audio_file_url}
              urlToClosedCaptionVtt={prescriptionAudioConfig.vtt_transcript_file_url}
            />
          </Box>
        )}
        {!member.uses_prescriptions && (
          <QuestionContainer className="question-wrapper">
            <Box pos="relative" className="question-container">
              <Paragraph size="small">
                {prescriptionLabel}
                {!isProfileAudioEnabled && (
                  <Tooltip
                    id={`${member.external_id}-prescription-tooltip`}
                    data-testid={tooltipId}
                    label={profileCard.tooltips.prescriptions.text}
                  >
                    <Box as="span" className="icon" pos="relative" top="4px">
                      <Icon type="Question" color="--text-black" />
                    </Box>
                  </Tooltip>
                )}
              </Paragraph>
            </Box>
            <div className="toggle-container" data-testid={`${member.external_id}-prescriptions-input`}>
              <ToggleList
                label={prescriptionLabel}
                groupName={`prescriptions-${relationship}-${member.external_id}`}
                className="pingdom-uses-prescriptions"
                data-testid={`${member.external_id}-prescription-input`}
                name={`usesPrescriptions-${member.external_id}`}
                options={[
                  {
                    option: optionY,
                    value: 'true',
                  },
                  {
                    option: optionN,
                    value: 'false',
                  },
                ]}
                handleChange={handleToggleInput}
                color="--colors-gray-black"
                selected={form.usesPrescriptions?.toString()}
                sizing="small"
                stretch
              />
            </div>
          </QuestionContainer>
        )}
        {member.uses_prescriptions && (
          <PrescriptionContainer>
            <PrescriptionForm
              handleEdit={handleEdit}
              queryDrugs={queryDrugs}
              results={drugResults}
              isLoading={isRxLoading}
              isDisabled={isRxSkipped}
              isMemberComplete={isComplete}
              member={member}
              memberConfig={memberConfig}
            />
            {member.prescriptions.length < 1 && (
              <Button
                size="small"
                onClick={() =>
                  handleInputChange({
                    target: {
                      name: 'usesPrescriptions',
                      value: false,
                    },
                  })
                }
              >
                <Text
                  field="profile_section.member.no_prescription_button"
                  vars={{
                    x:
                      relationship === 'policyholder'
                        ? profileCard.pronoun_map.i
                        : profileCard.pronoun_map.they,
                  }}
                />
              </Button>
            )}
          </PrescriptionContainer>
        )}
      </Box>
    );
  };

  const renderPrescriptionUtilLine = () => {
    if (member.uses_prescriptions || member.uses_utilization) {
      return <QuestionHorizontalLine />;
    }

    return null;
  };

  const renderUtilizationInput = () => {
    const utilizationLabel = (
      <Text
        field="profile_section.member.utilization_header"
        vars={{
          x:
            relationship === 'policyholder'
              ? profileCard.pronoun_map.you.toLowerCase()
              : profileCard.pronoun_map.they.toLowerCase(),
        }}
      />
    );

    return (
      <Box pos="relative">
        {isProfileAudioEnabled && (
          <Box pos="absolute" top={-2} left={-12}>
            <AudioTooltip
              data-testid={audioTooltipId}
              urlToAudio={utilAudioConfig.audio_file_url}
              urlToClosedCaptionVtt={utilAudioConfig.vtt_transcript_file_url}
            />
          </Box>
        )}
        {!member.uses_utilization && (
          <QuestionContainer className="question-wrapper">
            <Box pos="relative" className="question-container">
              <Paragraph size="small">
                {utilizationLabel}
                {!isProfileAudioEnabled && (
                  <Tooltip
                    id={`${member.external_id}-utilization-tooltip`}
                    data-testid={tooltipId}
                    label={profileCard.tooltips.utilization.text}
                  >
                    <Box as="span" className="icon" pos="relative" top="4px">
                      <Icon type="Question" color="--text-black" />
                    </Box>
                  </Tooltip>
                )}
              </Paragraph>
            </Box>
            <div className="toggle-container" data-testid={`${member.external_id}-utilization-input`}>
              <ToggleList
                label={utilizationLabel}
                groupName={`utilizations-${relationship}-${member.external_id}`}
                className="pingdom-uses-utilization"
                name={`usesUtilization-${member.external_id}`}
                options={[
                  {
                    option: optionY,
                    value: 'true',
                  },
                  {
                    option: optionN,
                    value: 'false',
                  },
                ]}
                handleChange={handleToggleInput}
                color="--colors-gray-black"
                selected={member.uses_utilization?.toString()}
                sizing="small"
                stretch
              />
            </div>
          </QuestionContainer>
        )}
        {member.uses_utilization && (
          <UtilContainer>
            <UtilizationForm
              member={member}
              handleEdit={handleEdit}
              memberConfig={memberConfig}
              isProfileAudioEnabled={isProfileAudioEnabled}
              isPlannedProceduresEnabled={enablePlannedProcedures}
            />
            {!getIsValidUtilization() && (
              <Button
                size="small"
                onClick={() =>
                  handleInputChange({
                    target: {
                      name: 'usesUtilization',
                      value: false,
                    },
                  })
                }
              >
                <Text
                  field="profile_section.member.no_utilization_button"
                  vars={{
                    x:
                      relationship === 'policyholder'
                        ? profileCard.pronoun_map.i
                        : profileCard.pronoun_map.they,
                  }}
                />
              </Button>
            )}
          </UtilContainer>
        )}
      </Box>
    );
  };

  const renderUtilPlannedProceduresLine = () => {
    if (!enablePlannedProcedures) {
      return null;
    }

    if (member.uses_utilization || member.uses_planned_procedures) {
      return <QuestionHorizontalLine />;
    }

    return null;
  };

  const renderPlannedProceduresInput = () => {
    if (!enablePlannedProcedures) {
      return null;
    }

    const plannedProceduresHeaderLabel =
      relationship === 'policyholder' ? (
        <Text field="profile_section.member.planned_procedures.header_policyholder" />
      ) : (
        <Text field="profile_section.member.planned_procedures.header_household_member" />
      );

    const plannedProceduresHeaderTooltip =
      relationship === 'policyholder'
        ? profileCard.planned_procedures.tooltips.header_policyholder
        : profileCard.planned_procedures.tooltips.header_household_member;

    const noPlannedProceduresButtonLabel =
      relationship === 'policyholder' ? (
        <Text field="profile_section.member.planned_procedures.no_procedures_button_policyholder" />
      ) : (
        <Text field="profile_section.member.planned_procedures.no_procedures_button_household_member" />
      );

    const plannedProceduresAudioConfig: ProfileAudio = getProfileAudioConfig(
      AudioConfigTooltipContentfulPaths.PlannedProceduresQuestionAudio,
    );

    return (
      <Box pos="relative">
        {isProfileAudioEnabled && (
          <Box pos="absolute" top={-2} left={-12}>
            <AudioTooltip
              data-testid={audioTooltipId}
              urlToAudio={plannedProceduresAudioConfig.audio_file_url}
              urlToClosedCaptionVtt={plannedProceduresAudioConfig.vtt_transcript_file_url}
            />
          </Box>
        )}
        {!member.uses_planned_procedures && (
          <QuestionContainer className="question-wrapper">
            <div className="question-container">
              <Paragraph size="small">
                {plannedProceduresHeaderLabel}
                {!isProfileAudioEnabled && (
                  <Tooltip
                    id={`${member.external_id}-planned-procedures-tooltip`}
                    data-testid={tooltipId}
                    label={plannedProceduresHeaderTooltip.text}
                  >
                    <Box as="span" className="icon" pos="relative" top="4px">
                      <Icon type="Question" color="--text-black" />
                    </Box>
                  </Tooltip>
                )}
              </Paragraph>
            </div>
            <div className="toggle-container" data-testid={`${member.external_id}-planned-procedures-input`}>
              <ToggleList
                label={plannedProceduresHeaderLabel}
                groupName={`planned-procedures-${relationship}-${member.external_id}`}
                className="pingdom-uses-planned-procedures"
                name={`usesPlannedProcedures-${member.external_id}`}
                options={[
                  {
                    option: optionY,
                    value: 'true',
                  },
                  {
                    option: optionN,
                    value: 'false',
                  },
                ]}
                handleChange={handleToggleInput}
                color="--colors-gray-black"
                selected={member.uses_planned_procedures?.toString()}
                sizing="small"
                stretch
              />
            </div>
          </QuestionContainer>
        )}
        {member.uses_planned_procedures && (
          <UtilContainer>
            <PlannedProceduresForm
              handleEdit={handleEdit}
              member={member}
              memberConfig={memberConfig}
              isProfileAudioEnabled={isProfileAudioEnabled}
              relationship={relationship as Relationship}
            />
            {!getIsValidPlannedProcedures() && (
              <Button
                size="small"
                onClick={() =>
                  handleInputChange({
                    target: {
                      name: 'usesPlannedProcedures',
                      value: false,
                    },
                  })
                }
              >
                {noPlannedProceduresButtonLabel}
              </Button>
            )}
          </UtilContainer>
        )}
      </Box>
    );
  };

  const sexAndAgeSectionClasses = ['sex-and-age'];
  if (isSexInputPreferNoAnswerEnabled) {
    sexAndAgeSectionClasses.push('flex-column');
  }

  return (
    <MemberEdit
      className={`edit-${member.external_id}-btn`}
      color={currentColor()}
      data-testid={`add-member-${relationship}`}
    >
      {renderHeader()}

      <MemberForms data-testid={`${member.external_id}-form`}>
        <section className={sexAndAgeSectionClasses.join(' ')}>
          {renderSexInput()}
          {renderAgeInput()}
        </section>
        {renderDomesticPartnerInput()}
        {/* //TODO NEEDS PINGDOM SELECTOR */}
        {renderTobaccoInput()}
        {renderTobaccoPrescriptionLine()}
        {renderPrescriptionInput()}
        {renderPrescriptionUtilLine()}
        {renderUtilizationInput()}
        {renderUtilPlannedProceduresLine()}
        {renderPlannedProceduresInput()}
      </MemberForms>
    </MemberEdit>
  );
};

export default HouseholdMember;
