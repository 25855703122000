import { useDisclosure } from '@chakra-ui/react';
import React from 'react';

import { getSelectableBenefits } from 'Containers/OverviewPage/constants';
import { RiskAversion, Survey } from 'Containers/ProfilePage/types';
import BenefitDetails from 'Containers/ViewBenefitPage/BenefitDetails';
import { EducationalBenefitDetails } from 'Containers/ViewBenefitPage/EducationalBenefitDetails';
import { BuilderBenefit, InsuranceType } from 'Containers/ViewBenefitPage/types';
import { ContentfulFbsBenefit } from 'ContentfulDefaults/types/benefits';
import { useTextContext } from 'Contexts/textContext';
import { Button, Paragraph } from 'DesignLibrary/atoms';
import { useViewport } from 'DesignLibrary/context';
import Text from 'Shared/Text';
import { ReasonsWhy, SupplementalPlan } from 'Types/entities';
import { BuilderGoSettings } from 'Utils/apiTypes';

import { TwoButtons } from './styled';

export interface PlanContentProps {
  insuranceType: InsuranceType;
  description: string;
  client: string;
  employerName: string;
  builderBenefits?: Record<string, BuilderBenefit> | Record<string, never>;
  nonSelectablePlans: SupplementalPlan[];
  healthHsaEligible: boolean;
  title: string;
  isEducational: boolean;
  reasonsWhy: ReasonsWhy;
  selectedHealthDeductible: number;
  dependentsCount: number;
  surveyAnswers: Survey;
  riskAversion: RiskAversion;
  planCount?: number;
  builderCustomerKey: string | null;
  educationalVideoConfigs: BuilderGoSettings | null;
  handleOpenBenefit: (insuranceType: InsuranceType) => void;
}

const PlanContent = ({
  insuranceType,
  description,
  client,
  employerName,
  builderBenefits,
  nonSelectablePlans,
  healthHsaEligible,
  title,
  isEducational,
  reasonsWhy,
  selectedHealthDeductible,
  dependentsCount,
  surveyAnswers,
  riskAversion,
  planCount,
  builderCustomerKey,
  educationalVideoConfigs,
  handleOpenBenefit,
}: PlanContentProps) => {
  const { device } = useViewport();
  const { retrieveContentfulData } = useTextContext();
  const { isOpen: benefitsOpen, onOpen: benefitsOnOpen, onClose: benefitsOnClose } = useDisclosure();

  const learnMoreButtonLabel = `${retrieveContentfulData<string>(
    'benefits_section.button_text.learnMore',
  )} ${title}`;

  const viewPlansButtonLabel = `${retrieveContentfulData<string>('benefits_section.button_text.viewPlans')} ${title}`;

  const educationalBenefits = retrieveContentfulData<ContentfulFbsBenefit[]>(
    'benefits_section.educational_benefits',
    [],
  );

  const educationalBenefitIndex = educationalBenefits.findIndex(
    (benefit) => benefit.plan_type === insuranceType,
  );

  const educationalBenefitDetails =
    educationalBenefitIndex >= 0 ? educationalBenefits[educationalBenefitIndex] : null;

  const getBenefitDetails = (isNonSelectable: boolean) => (
    <BenefitDetails
      employerName={employerName}
      nonSelectablePlans={isNonSelectable ? nonSelectablePlans : undefined}
      insuranceType={insuranceType}
      builderBenefits={builderBenefits || {}}
      healthHsaEligible={healthHsaEligible}
      isOpen={benefitsOpen}
      onClose={benefitsOnClose}
      reasonsWhy={reasonsWhy}
      selectedHealthDeductible={selectedHealthDeductible}
      dependentsCount={dependentsCount}
      surveyAnswers={surveyAnswers}
      riskAversion={riskAversion}
      handleOpenBenefit={() => handleOpenBenefit(insuranceType)}
      planCount={isNonSelectable ? 0 : planCount}
      builderCustomerKey={builderCustomerKey}
      educationalVideoConfigs={educationalVideoConfigs}
    />
  );

  const getLearnMoreButton = () => (
    <Button
      aria-label={learnMoreButtonLabel}
      size={device === 'mobile' ? 'small' : 'normal'}
      data-testid={`learn-${insuranceType}-btn`}
      className="open-benefit-btn"
      onClick={benefitsOnOpen}
      stretch
    >
      <Text field="benefits_section.button_text.learnMore" />
    </Button>
  );

  return (
    <div className="about-container">
      <Paragraph size="small" className="type-description">
        {description}
      </Paragraph>
      <section className="cta">
        {isEducational || !getSelectableBenefits(client).includes(insuranceType) ? (
          <>
            {getLearnMoreButton()}
            {educationalBenefitDetails ? (
              // Contentful only Educational Benefits
              <EducationalBenefitDetails
                benefitDetails={educationalBenefitDetails}
                benefitIndex={educationalBenefitIndex}
                isOpen={benefitsOpen}
                onClose={benefitsOnClose}
              />
            ) : (
              // All NonSelectable Benefits with "plans"
              getBenefitDetails(true)
            )}
          </>
        ) : (
          <TwoButtons>
            {getLearnMoreButton()}
            <Button
              aria-label={viewPlansButtonLabel}
              buttonType="primary"
              size={device === 'mobile' ? 'small' : 'normal'}
              iconRight="ArrowRight"
              data-testid={`choose-${insuranceType}-btn`}
              className={`open-benefit-btn ${`pingdom-choose-${insuranceType}`}`}
              onClick={() => handleOpenBenefit(insuranceType)}
              stretch
            >
              <Text field="benefits_section.button_text.viewPlans" />
            </Button>
            {getBenefitDetails(false)}
          </TwoButtons>
        )}
      </section>
    </div>
  );
};

export default PlanContent;
