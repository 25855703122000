import { useDisclosure } from '@chakra-ui/react';
import { isEmpty } from 'lodash';
import React, { useState, useEffect } from 'react';

import EducationalDrawer from 'Containers/_drawers/EducationalDrawer';
import { usePremiumContext } from 'Contexts/premiumContext';
import { Button, H3, H6, Numbers, NumberWithPayPeriod, Spinner } from 'DesignLibrary/atoms';
import { TooltipCaps } from 'DesignLibrary/molecules';
import { Recommendation } from 'Types/entities';
import { BuilderGoSettings } from 'Utils/apiTypes';
import { formatDate, toDollars } from 'Utils/helpers';

import { FsaSlider } from './FsaSlider';
import {
  BottomContent,
  Bullets,
  ChartContainer,
  FsaNumbers,
  FsaNumbersContainer,
  TopContent,
  TopContentContainer,
} from './styled';
import { AccountContainer } from '../_shared/AccountContainer';
import { Bullet } from '../_shared/Bullet';
import { MAX_FSA_CONTRIBUTION } from '../constants';

export interface FsaSectionProps {
  fsaData: any; // TODO: type this
  isFsaTaxSavingsLoading: boolean;
  lockedFsaContribution: number | null;
  hsaEligible: boolean;
  selectedPlan: Recommendation;
  employerHsaContribution: number;
  isFsaEligible: boolean;
  fetchFsaTaxSavings: () => void;
  handleLockedFsaContribution: (contribution: number) => void;
  educationalVideoConfigs: BuilderGoSettings | null;
}

export const FsaSection = ({
  fsaData,
  isFsaTaxSavingsLoading,
  lockedFsaContribution,
  hsaEligible,
  selectedPlan,
  employerHsaContribution,
  isFsaEligible,
  fetchFsaTaxSavings,
  handleLockedFsaContribution,
  educationalVideoConfigs,
}: FsaSectionProps) => {
  const [contribution, setContribution] = useState(0);
  const { payCycle } = usePremiumContext();

  // TODO:
  // This 'fsa_key_figures' object will eventually come from the 'selectedPlan' object
  // Once they are configured correctly in the API, deprecate this object and use the 'selectedPlan' values
  const fsa_key_figures = {
    rollover_limit: 1000,
    grace_period: new Date(),
  };

  const rolloverLimit = fsa_key_figures.rollover_limit || null;
  const gracePeriod = new Date(fsa_key_figures.grace_period) || null;

  const gracePeriodText = `Until ${formatDate(fsa_key_figures.grace_period.toISOString(), 'en-US')}`;

  useEffect(() => {
    // initial fetch
    if (isFsaEligible && isEmpty(fsaData)) {
      fetchFsaTaxSavings();
    }
  }, []);

  useEffect(() => {
    if (isFsaEligible) {
      // fetch when employee contribution amount changes
      if (typeof lockedFsaContribution === 'number') {
        fetchFsaTaxSavings();
      }
    }
  }, [lockedFsaContribution]);

  const { isOpen: eduOpen, onOpen: eduOnOpen, onClose: eduOnClose } = useDisclosure();

  const isLimitedPurpose = hsaEligible;
  const accountText = isLimitedPurpose ? 'LPFSA' : 'FSA';

  return (
    <>
      <AccountContainer account="fsa">
        <TopContentContainer>
          <TopContent>
            {/* TODO: CONTENTFUL Add Field */}
            <H6>{accountText} Contributions</H6>
            <H3 className="callout" as="p" weight="normal" lineHeight={1.5}>
              {/* TODO: CONTENTFUL Add Field */}
              As a tax-advantaged account, the more you contribute to your {accountText}, the higher your tax
              savings.
            </H3>
            <Bullets>
              <Bullet icon="CurrencyDollar" color="blue">
                {/* TODO: CONTENTFUL Add Field */}
                However, the amount you contribute to your {accountText} is completely up to you and your
                personal situation.
              </Bullet>

              <Bullet icon="LockSimple" color="blue">
                {/* TODO: CONTENTFUL Add Field */}
                Remember, you are limited to what you can spend {accountText} funds on.
              </Bullet>

              <Bullet icon="Cursor" color="blue">
                {/* TODO: CONTENTFUL Add Field */}
                Edit your contribution amount below to see potential tax savings.
              </Bullet>
            </Bullets>
          </TopContent>
          <FsaNumbers>
            <div className="title">
              <H6 color="--primary-white">Key 2023 Numbers</H6>
            </div>
            <div className="content">
              <div className="item">
                {/* TODO: CONTENTFUL Add Field + Tooltip */}
                <TooltipCaps tooltip="Fix Me" keyHint="max-contribution">
                  Max Contribution
                </TooltipCaps>
                <Numbers>{toDollars(MAX_FSA_CONTRIBUTION)}</Numbers>
              </div>
              {rolloverLimit ? (
                <div className="item">
                  {/* TODO: CONTENTFUL Add Field + Tooltip */}
                  <TooltipCaps tooltip="Fix Me" keyHint="rollover-limit">
                    Rollover Limit
                  </TooltipCaps>
                  <Numbers>{toDollars(rolloverLimit)}</Numbers>
                </div>
              ) : null}
              {gracePeriod && (
                <div className="item">
                  {/* TODO: CONTENTFUL Add Field + Tooltip */}
                  <TooltipCaps tooltip="Fix Me" keyHint="grace-period">
                    Grace Period
                  </TooltipCaps>
                  <Numbers>{gracePeriodText}</Numbers>
                </div>
              )}
            </div>
            {/** // TODO - Accessible via selector pulling from fbs fsa plan; blocked until APP-52 complete */}
          </FsaNumbers>
        </TopContentContainer>

        <ChartContainer>
          <FsaSlider
            contribution={contribution}
            lockedFsaContribution={lockedFsaContribution}
            userContributionLimit={MAX_FSA_CONTRIBUTION}
            setContribution={setContribution}
            setLockedContribution={handleLockedFsaContribution}
          />

          <FsaNumbersContainer>
            <div className="top-numbers">
              <div className="item">
                {/* TODO: CONTENTFUL Add Field + Tooltip */}
                <TooltipCaps tooltip="Fix Me" keyHint="yearly-fsa-contribution">
                  Yearly Contribution
                </TooltipCaps>
                {isFsaTaxSavingsLoading ? (
                  <Spinner color="--alex-border-gray" />
                ) : (
                  <NumberWithPayPeriod payPeriodType="yearly" value={toDollars(lockedFsaContribution)} />
                )}
              </div>
              <div className="item">
                {/* TODO: CONTENTFUL Add Field + Tooltip */}
                <TooltipCaps tooltip="Fix Me" keyHint="pay-period-fsa-contribution">
                  Per Pay Period
                </TooltipCaps>
                {isFsaTaxSavingsLoading ? (
                  <Spinner color="--alex-border-gray" />
                ) : (
                  <NumberWithPayPeriod
                    value={toDollars(lockedFsaContribution && lockedFsaContribution / payCycle)}
                  />
                )}
              </div>
              <div className="item blue">
                {/* TODO: CONTENTFUL Add Field + Tooltip */}
                <TooltipCaps color="--primary-white" tooltip="Fix Me" keyHint="yearly-fsa-contribution">
                  Yearly Tax Savings
                </TooltipCaps>
                {isFsaTaxSavingsLoading ? (
                  <Spinner color="--primary-white" />
                ) : (
                  <div className="number-color">
                    <NumberWithPayPeriod
                      payPeriodType="yearly"
                      value={toDollars(fsaData?.annual_fsa_tax_savings || 0)}
                      prefix="+"
                      fontWeight="bold"
                    />
                  </div>
                )}
              </div>
            </div>
          </FsaNumbersContainer>
        </ChartContainer>

        <BottomContent>
          <Bullets>
            <Bullet icon="QuestionOutline" color="blue">
              <div className="include-button">
                {/* TODO: CONTENTFUL Add Field */}
                Have any additional {accountText} questions?
                <Button onClick={eduOnOpen} size="small">
                  {/* TODO: CONTENTFUL Reuse 'Learn More' field */}
                  Learn More
                </Button>
              </div>
            </Bullet>
            <Bullet icon="Check" color="blue">
              {/* TODO: CONTENTFUL Add Field */}
              If not, you&apos;re all set with your {accountText} contribution of{' '}
              {toDollars(lockedFsaContribution)}/yr. Your employer will have more info about enrolling on your
              enrollment platform.
            </Bullet>
          </Bullets>
        </BottomContent>
      </AccountContainer>
      <EducationalDrawer
        isOpen={eduOpen}
        infoType="fsa"
        selectedPlan={selectedPlan}
        handleClose={eduOnClose}
        // Should this be an optional prop?
        employerContribution={employerHsaContribution}
        shouldShowEducationalVideo={!!educationalVideoConfigs?.show_fsa_video}
      />
    </>
  );
};
