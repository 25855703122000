import { ContentfulProfileSection, PlannedProceduresSection } from 'ContentfulDefaults/types/profile';

const plannedProcedures: PlannedProceduresSection = {
  header_policyholder: 'In the next 12 months, do you have any known medical needs?',
  header_household_member: 'In the next 12 months, do they have any known medical needs?',
  instructions_policyholder: 'Add healthcare needs that you expect for the next 12 months.',
  instructions_household_member: 'Add healthcare needs that they expect for the next 12 months.',
  no_procedures_button_policyholder: 'I don’t have any known needs for the year ahead.',
  no_procedures_button_household_member: 'They don’t have any known needs for the year ahead.',
  tooltips: {
    header_policyholder: {
      text: 'This information helps ALEX account for the cost of any care you expect to need in the year ahead and provide more personalized recommendations.',
      title: 'Planned Procedures',
      underline: 'Planned Procedures',
    },
    header_household_member: {
      text: 'This information helps ALEX account for the cost of any care your family expects to need in the year ahead and provide more personalized recommendations.',
      title: 'Planned Procedures',
      underline: 'Planned Procedures',
    },
    inpatient_procedures: {
      text: 'An inpatient surgery requires at least one night’s stay in the hospital following the procedure.',
      title: 'Inpatient Surgeries',
      underline: 'Inpatient Surgeries',
    },
    outpatient_procedures: {
      text: 'An outpatient surgery is a procedure that is handled in one day, without requiring an overnight hospital stay.',
      title: 'Outpatient Surgeries',
      underline: 'Outpatient Surgeries',
    },
    routine_therapy_visits_policyholder: {
      text: 'How many physical therapy visits do you expect in the next 12 months? Include any chiropractic visits.',
      title: 'Physical Therapy (Including chiropractic)',
      underline: 'Physical therapy (Including chiropractic)',
    },
    routine_therapy_visits_household_member: {
      text: 'How many physical therapy visits do they expect in the next 12 months? Include any chiropractic visits.',
      title: 'Physical Therapy (Including chiropractic)',
      underline: 'Physical therapy (Including chiropractic)',
    },
    mental_health_visits_policyholder: {
      text: 'How many times do you expect to visit a mental health provider (e.g. therapist, psychiatrist, or psychologist) in the next 12 months?',
      title: 'Mental Health Visits',
      underline: 'Mental Health Visits',
    },
    mental_health_visits_household_member: {
      text: 'How many times do they expect to visit a mental health provider (e.g. therapist, psychiatrist, or psychologist) in the next 12 months?',
      title: 'Mental Health Visits',
      underline: 'Mental Health Visits',
    },
  },
};

export const profile: {
  profile_section: ContentfulProfileSection;
} = {
  profile_section: {
    contentful_name: 'Profile Section (DEFAULT DO NOT CHANGE)',
    page_header: {
      text: 'Complete Your Profile',
      text_return_user: 'Update Your Profile',
      subtext: 'Here are a couple of quick questions so that we can get to know you.',
      subtext_return_user: 'Review your answers and make any updates to find your best benefits.',
    },
    navigation: {
      risk: 'Risk',
      income: 'Income',
      member: 'You and Family',
      pregnancy: 'Pregnancy',
      medication: 'Prescriptions',
      pillContent: {
        risk: ['Low', 'Med', 'High'],
        pregnant: 'Baby',
      },
      utilization: 'Utilization',
      capacityToPay: 'Capacity to Pay',
    },
    button_text: {
      risk: 'Continue',
      income: 'Continue',
      member: {
        myself: "I'm just covering myself",
        meAndChild: "I'm covering myself and my child",
        meAndOthers: "I'm covering myself and {x} others",
        meAndSpouse: "I'm covering myself and my spouse/partner",
        meAndOthersMobile: 'Im covering {x} people',
      },
      capacity: 'Continue',
      pregnancy: {
        N: 'No',
        Y: 'Yes',
      },
      scroll_up: 'Error on the {x} question',
      memberCard: 'Save',
      scroll_down: 'Scroll Down',
      utilization: 'Show Me My Results',
      prescriptions: {
        soloOne: 'I take 1 prescription',
        soloMany: 'I take {x} prescriptions',
        soloNone: "I don't take prescriptions",
        familyOne: 'My family takes 1 prescription',
        familyMany: 'My family takes {x} prescriptions',
        familyNone: "My family doesn't take prescriptions",
      },
      people_like_you: 'Continue',
      close_button: 'Close',
    },
    card_names: {
      risk: 'risk',
      utilization: 'utilization',
      income: 'income',
      capacity: 'capacity to pay',
      location: 'residence',
      household: 'household',
      pregnancy: 'pregnancy',
      enrollment: 'enrollment',
      eligibility: 'eligibility',
      prescription: 'prescriptions',
      incentives: 'incentives',
    },
    eligibility_heading:
      'Before we begin, please answer the following questions so that we can determine which plans you are eligible for.',
    location_heading: 'Where do you live?',
    member_headings: {
      others: 'Who will be covered on your plan?',
      single: 'Who will be covered on your plan?',
    },
    member_description:
      'We’ll use this information to identify the health plans and premiums that are available to you, and we’ll also start to get a picture of how much each of your health insurance plans will cost.',
    member_card_data: {
      person: {
        spouse: 'Spouse / Partner',
        dependent: 'Dependent',
        policyholder: 'You',
        policyholder_enter: 'Add Your Info',
      },
      ageTitle: 'Age',
      sexTitle: 'Sex',
      zipTitle: 'Zipcode',
      sexOptions: {
        F: 'Female',
        M: 'Male',
      },
      stateTitle: 'State',
      tobaccoUse: 'Uses Tobacco',
      tobaccoInfo: 'You may be subject to a tobacco surcharge on some of your eligible benefits.',
    },
    individual_only:
      'Note: Based on your answers to the eligibility questions, the plans available to you do not cover any dependents.',
    pregnancy_heading: 'Is your family planning for a pregnancy in the upcoming year?',
    pregnancy_description:
      'Pregnancies are expensive. We’ll take this information into account to deliver more accurate recommendations for your family’s medical needs next year.',
    pregnancy_illustration:
      'https://picwellapppublicdata.s3.amazonaws.com/contentful/profileimages/walking-baby.svg',
    income_heading: 'What is the combined household income of your family?',
    income_description:
      'Some of your benefits come with tax breaks. We’ll use your income to calculate the value of those tax breaks.',
    tax_heading: 'These next questions help us calculate tax benefits related to health insurance.',
    tax_description:
      'Some of your benefits come with tax breaks. We’ll use your tax filing status and household income to calculate the value of those tax breaks.',
    tax_questions_text: {
      filingStatus: {
        title: 'What is your tax filing status?',
        options: {
          single: 'Single',
          default: 'Select Filing Status',
          married: 'Married',
          headOfHousehold: 'Head of Household',
          marriedFilingSeparate: 'Married Filing Separately',
        },
      },
      spouseIncome: {
        title: 'What is your spouse’s annual income before taxes?',
      },
      policyholderIncome: {
        title: 'What is your annual income before taxes? (It’s OK to estimate here)',
      },
    },
    income_illustration:
      'https://picwellapppublicdata.s3.amazonaws.com/contentful/profileimages/income-illustration.svg',
    prescriptions_heading: 'Which prescriptions do you or your family take?',
    prescriptions_headings: {
      self: 'What prescriptions do you regularly take?',
      family: 'What prescriptions does your family regularly take?',
      prescription_warning_text:
        '{x} prescription(s) added. Be sure to add all prescriptions before moving on.',
    },
    prescriptions_description:
      'When you tell us about the prescription drugs that you routinely take, we’re able to use that to provide more personalized recommendations that account for the costs and risks of people who take similar prescriptions.',
    prescriptions_illustration:
      'https://picwellapppublicdata.s3.amazonaws.com/contentful/profileimages/medication.svg',
    drug_search_text: {
      isLoading: 'Loading...',
      mobileAdd: '+ Add Prescription',
      placeholder: 'Search to add prescriptions...',
      noResultsFound: 'No results found.',
      noPrescriptions: 'No prescriptions added.',
    },
    risk_heading: 'Tell us which type of health plan you would prefer in the scenario below.',
    risk_description: {
      data: {},
      content: [
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value:
                'We’ll use your response to get a sense of how risk averse you are. Risk aversion describes how comfortable you are facing risk, and we’ll account for these preferences when we evaluate which benefits are best for you.',
              nodeType: 'text',
            },
          ],
          nodeType: 'paragraph',
        },
      ],
      nodeType: 'document',
    },
    questions_text: {
      questionOne: {
        title:
          "Let's say you have a low chance of needing expensive medical care in the next year. Which of these plans would you choose?",
        optionOne: {
          footer: 'Then pay less out-of-pocket if I need expensive, unexpected medical care.',
          heading: 'Pay more each month for my health coverage.',
        },
        optionTwo: {
          footer: 'Then pay more out-of-pocket if I need expensive, unexpected medical care.',
          heading: 'Pay less each month for my health coverage.',
        },
      },
      questionTwo: {
        title:
          'Now, what if your chance of being injured and requiring expensive medical care increases to a medium chance. Which of these plans would you choose?',
        optionOne: {
          footer: 'Then pay less out-of-pocket if I need expensive, unexpected medical care.',
          heading: 'Pay more each month for my health coverage.',
        },
        optionTwo: {
          footer: 'Then pay more out-of-pocket if I need expensive, unexpected medical care.',
          heading: 'Pay less each month for my health coverage.',
        },
      },
    },
    risk_aversion_info: {
      low: {
        text: 'It looks like you have a LOW level of risk aversion. This means that you are willing to face more risk if it gives you the best opportunity to save money.',
        header: 'Low Risk Aversion',
      },
      high: {
        text: 'It looks like you have a HIGH level of risk aversion. This means that you are willing to pay more for a plan that offers more coverage.',
        header: 'High Risk Aversion',
      },
      medium: {
        text: 'It looks like you have a MEDIUM level of risk aversion. This means that you are looking for a balance between risk protection and savings.',
        header: 'Medium Risk Aversion',
      },
    },
    capacity_heading:
      "If you need to receive unexpected medical care, what's the largest bill you can afford to pay?",
    capacity_description:
      'We’ll use this information to adjust your plan recommendations based on how likely each plan is to expose you to medical bills that you can’t afford.',
    cost_thresholds: {
      lt_500: 'Less than $500',
      gt_6000: '$6,000 or more',
      '500_1000': '$500 - $999',
      '1000_2000': '$1,000 - $1,999',
      '2000_3000': '$2,000 - $2,999',
      '3000_4000': '$3,000 - $3,999',
      '4000_6000': '$4,000 - $5,999',
    },
    utilization_heading:
      'Now we’ll ask you to answer a few questions about your health care use over the past 12 months.',
    utilization_description:
      'We’ll use this to further refine our predictions of the health care costs that you are likely to face next year. While it is often the case that someone’s health care needs vary from year to year, knowing about your past health care use helps us provide more personalized recommendations.',
    util_questions: {
      pcpVisit:
        'How many times did {x} visit {y} Primary Care Provider (excluding {y} routine physical exam) in the last 12 months?',
      specialVisit: 'How many times did {x} visit a Specialist in the last 12 months?',
      hospitalVisit:
        'How many days were {x} admitted to a hospital (excluding hospital admissions for childbirth) in the last 12 months?',
      indvAndFamily: ' or your family',
      mentalHealthVisit: 'How many times did {x} visit a Mental Health provider in the last 12 months?',
    },
    error_text: {
      invalidZipCodeInput: 'Incorrect',
      invalidEligibilityInput: '"{x}" is not a valid input for this question.',
    },

    // V3 REFACTORING...
    eligibility: {
      contentful_name: '[DEFAULT] Eligibility Card',
      name: 'Eligibility',
      is_full_time_or_part_time: 'Are you a full-time or part-time employee?',
      annual_policyholder_salary: 'What is your employee salary?',
      occupation: 'Which best describes you?',
      tooltips: {
        name: {
          text: 'This is the eligibility tooltip',
          title: 'Eligibility',
          underline: 'Eligibility',
        },
      },
    },
    enrollment: {
      contentful_name: '[DEFAULT] Enrollment Card',
      name: 'Enrollment',
      enrollment_reason_question: 'What best describes your situation?',
      enrollment_reason_open_enrollment: "I'm here for open enrollment.",
      enrollment_reason_new_hire: "I'm a new hire.",
      enrollment_reason_qle: 'I had a qualifying life event.',
      enrollment_reason_info_current_year: "I need info about this year's benefits.",
      enrollment_reason_info_next_year: "I need info about next year's benefits.",
      tooltips: {
        name: {
          text: 'A qualifying life event is a major life change that allows you to make adjustments to your benefits outside of the regular enrollment period, including getting married or divorced, adding a child to your family, or changes in residence.',
          title: 'Enrollment',
          underline: 'Enrollment',
        },
      },
    },
    location: {
      contentful_name: '[DEFAULT] Location Card',
      name: 'Residence',
      header: 'Where do you reside?',
      zipcode_label: 'Zipcode',
      zipcode_placeholder: '11111',
      state_label: 'State',
      state_placeholder: 'Choose...',
      tooltips: {
        name: {
          text: 'This is the location tooltip',
          title: 'Residence',
          underline: 'Residence',
        },
      },
    },
    member: {
      contentful_name: '[DEFAULT] Member Card',
      name: 'Household',
      policyholder_card_header: 'You',
      spouse_card_header: 'Spouse',
      dependent_card_header: 'Dependent',
      domestic_partner_card_header: 'Spouse / Partner',
      age_label: 'Age',
      tobacco_label: 'Uses Tobacco',
      tobacco_header: 'Do {x} use any tobacco products?',
      spouse_label: 'Spouse',
      domestic_partner_label: 'Domestic Partner',
      domestic_partner_question_label: 'Is this dependent a spouse or domestic partner?',
      prescription_header: 'Do {x} take any prescription drugs regularly?',
      no_prescription_button: "{x} don't take any prescriptions",
      utilization_header:
        'In the past 12 months have {x} visited a PCP or specialist or been admitted to the hospital?',
      planned_procedures: plannedProcedures,
      no_utilization_button: '{x} didn’t have any visits/admissions',
      pronoun_map: {
        i: 'I',
        they: 'They',
        you: 'You',
      },
      drug_search_placeholder: 'Search to add prescriptions',
      utilization_instructions: 'Add healthcare usage for the past 12 months.',
      pcp_exclusions: 'Excluding routine physical exams.',
      hospital_exclusions: 'Excluding admissions for childbirth.',
      button_options: {
        no: 'No',
        yes: 'Yes',
        male: 'Male',
        female: 'Female',
        prefer_not_to_say: 'Prefer not to say',
      },
      coverage_text: {
        error: 'Invalid {x}',
        myself: 'You are just covering yourself',
        incomplete: 'Missing fields for {x}',
        meAndChild: 'You are just covering yourself and one child',
        meAndOthers: 'Covering yourself and {x} family members.',
        meAndSpouse: 'Covering yourself and your spouse',
        meAndSpouseDomesticPartner: 'Covering yourself and your spouse/partner',
        meAndDomesticPartner: 'Covering yourself and your partner',
        meAndOthersMobile: 'Im covering {x} people',
      },
      tooltips: {
        sex: {
          text: 'Your own personal health care needs may be correlated with your sex at birth. Answering this question allows us to deliver personalized recommendations that account for this factor.',
          title: 'Sex at birth',
          underline: 'Sex at birth',
        },
        name: {
          text: "We'll use this information to identify the health plans and premiums that are available to you, and we'll also start to get a picture of how much each of your health insurance plans will cost.",
          title: 'Household',
          underline: 'Household',
        },
        tobacco: {
          text: 'You may be subject to a tobacco surcharge on some of your eligible benefits.',
          title: 'Uses Tobacco',
          underline: 'Uses Tobacco',
        },
        pcp_visits: {
          text: 'How many times did {x} visit {y} Primary Care Provider (excluding {y} routine physical exam) in the last 12 months?',
          title: 'PCP Visits',
          underline: 'PCP Visits',
        },
        utilization: {
          text: 'We’ll use this to further refine our predictions of the health care costs that you are likely to face next year. While it is often the case that someone’s health care needs vary from year to year, knowing about your past health care use helps us provide more personalized recommendations.',
          title: 'Utilization',
          underline: 'Utilization',
        },
        prescriptions: {
          text: "When you tell us about the prescription drugs that you routinely take, we're able to use that to provide more personalized recommendations that account for the costs and risks of people who take similar prescriptions.",
          title: 'Prescriptions',
          underline: 'Prescriptions',
        },
        hospital_visits: {
          text: 'How many days were {x} admitted to a hospital (excluding hospital admissions for childbirth) in the last 12 months?',
          title: 'Hospital Admissions',
          underline: 'Hospital Admissions',
        },
        specialist_visits: {
          text: 'How many times did {x} visit a Specialist in the last 12 months?',
          title: 'Specialist Visits',
          underline: 'Specialist Visits',
        },
        mental_health_visits: {
          text: 'How many times did {x} visit a Mental Health provider in the last 12 months?',
          title: 'Mental Health Visits',
          underline: 'Mental Health Visits',
        },
      },
      add_policyholder_button: 'Add Yourself',
      add_spouse_button: 'Add Spouse',
      add_spouse_or_domestic_partner_button: 'Add Spouse / Partner',
      add_dependent_button: 'Add Dependent',
      accessibility: {
        pcp_counter: 'Number of pcp visits',
        specialist_counter: 'Number of specialist visits',
        inpatient_counter: 'Number of inpatient days',
        mental_health_counter: 'Number of mental health provider visits',
        remove_spouse_alert: 'Spouse removed',
        remove_dependent_alert: 'Dependent removed',
        remove_spouse: 'Remove spouse',
        remove_dependent: 'Remove dependent',
        drug_search_results_alert: '{x} results',
        add_drug_alert: 'Added {x}',
        remove_drug_alert: 'Removed {x}',
        remove_drug_button: 'Remove {x}',
      },
      add_member_disclaimer: {
        nodeType: 'document',
        data: {},
        content: [
          {
            nodeType: 'paragraph',
            data: {},
            content: [
              {
                nodeType: 'text',
                value: 'Enter in all household members that you want to cover on ',
                marks: [],
                data: {},
              },
              {
                nodeType: 'text',
                value: 'any',
                marks: [
                  {
                    type: 'bold',
                  },
                ],
                data: {},
              },
              {
                nodeType: 'text',
                value:
                  " of the benefits that your employer offers. If you don't need all of your household members on your health plan, you will have a chance to edit your covered household members later.",
                marks: [],
                data: {},
              },
            ],
          },
        ],
      },
      drug_search_label: 'Search..',
      pcp_visit_labels: {
        contentful_name: 'pcp visit ranges',
        zero_visits_label: '0',
        one_visit_label: '1',
        two_to_four_visits_label: '2 - 4',
        five_or_more_visits_label: '5+',
      },
      inpatient_labels: {
        contentful_name: '',
        zero_days_label: '0',
        one_to_two_days_label: '1 - 2',
        three_or_more_days_label: '3+',
      },
      specialist_visit_labels: {
        contentful_name: '',
        zero_visits_label: '0',
        one_to_six_visits_label: '1 - 6',
        seven_to_twelve_visits_label: '7 - 12',
        thirteen_or_more_visits_label: '13+',
      },
      text_note: {
        plan_year: 2024,
        short_text: {
          nodeType: 'document',
          data: {},
          content: [
            {
              nodeType: 'paragraph',
              content: [
                {
                  nodeType: 'text',
                  value: 'We can include a custom text note here up to 140 characters (2024)!',
                  marks: [],
                  data: {},
                },
              ],
              data: {},
            },
          ],
        },
        long_text: {
          nodeType: 'document',
          data: {},
          content: [
            {
              nodeType: 'paragraph',
              content: [
                {
                  nodeType: 'text',
                  value: 'Additional information can go here - up to 5000 characters!',
                  marks: [],
                  data: {},
                },
              ],
              data: {},
            },
          ],
        },
        button_text: 'Read more',
      },
      text_notes: [
        {
          plan_year: 2024,
          short_text: {
            nodeType: 'document',
            data: {},
            content: [
              {
                nodeType: 'paragraph',
                content: [
                  {
                    nodeType: 'text',
                    value: 'We can include a custom text note here up to 140 characters (2024)!',
                    marks: [],
                    data: {},
                  },
                ],
                data: {},
              },
            ],
          },
          long_text: {
            nodeType: 'document',
            data: {},
            content: [
              {
                nodeType: 'paragraph',
                content: [
                  {
                    nodeType: 'text',
                    value: 'Additional information can go here - up to 5000 characters!',
                    marks: [],
                    data: {},
                  },
                ],
                data: {},
              },
            ],
          },
          button_text: 'Read more',
        },
        {
          plan_year: 2025,
          short_text: {
            nodeType: 'document',
            data: {},
            content: [
              {
                nodeType: 'paragraph',
                content: [
                  {
                    nodeType: 'text',
                    value: 'We can include a custom text note here up to 140 characters (2025)!',
                    marks: [],
                    data: {},
                  },
                ],
                data: {},
              },
            ],
          },
          long_text: {
            nodeType: 'document',
            data: {},
            content: [
              {
                nodeType: 'paragraph',
                content: [
                  {
                    nodeType: 'text',
                    value: 'Additional information can go here - up to 5000 characters!',
                    marks: [],
                    data: {},
                  },
                ],
                data: {},
              },
            ],
          },
          button_text: 'Read more',
        },
      ],
    },
    pregnancy: {
      contentful_name: '[DEFAULT] Pregnancy Card',
      name: 'Pregnancy',
      header: 'Is your family planning for a pregnancy in the upcoming year?',
      button_options: {
        no: 'No',
        yes: 'Yes',
      },
      tooltips: {
        name: {
          text: 'Pregnancies are expensive. We’ll take this information into account to deliver more accurate recommendations for your family’s medical needs next year.',
          title: 'Pregnancy',
          underline: 'Pregnancy',
        },
      },
    },
    risk: {
      contentful_name: '[Default] Risk Card',
      name: 'Risk Aversion',
      tooltips: {
        name: {
          text: "We'll use your response to get a sense of how risk averse you are. Risk aversion describes how comfortable you are facing risk, and we'll account for these preferences when we evaluate which benefits are best for you.",
          title: 'Risk Aversion',
        },
      },
      header_question_a:
        "Let's say you have a low chance of needing expensive medical care in the next year. Which of these plans would you choose?",
      header_question_b:
        'Now, what if your chance of being injured and requiring expensive medical care increases to a medium chance. Which of these plans would you choose?',
      option_one_header: {
        data: {},
        content: [
          {
            data: {},
            content: [
              {
                data: {},
                marks: [
                  {
                    type: 'bold',
                  },
                ],
                value: 'Pay more each month for my health coverage.',
                nodeType: 'text',
              },
            ],
            nodeType: 'paragraph',
          },
        ],
        nodeType: 'document',
      },
      option_one_text: 'Then pay less out-of-pocket if I need expensive, unexpected medical care.',
      option_two_header: {
        data: {},
        content: [
          {
            data: {},
            content: [
              {
                data: {},
                marks: [
                  {
                    type: 'bold',
                  },
                ],
                value: 'Pay less each month for my health coverage.',
                nodeType: 'text',
              },
            ],
            nodeType: 'paragraph',
          },
          {
            data: {},
            content: [
              {
                data: {},
                marks: [],
                value: '',
                nodeType: 'text',
              },
            ],
            nodeType: 'paragraph',
          },
        ],
        nodeType: 'document',
      },
      option_two_text: 'Then pay more out-of-pocket if I need expensive, unexpected medical care.',
      risk_aversion_info: {
        low: {
          text: 'It looks like you have a LOW level of risk aversion. This means that you are willing to face more risk if it gives you the best opportunity to save money.',
          header: 'Low Risk Aversion',
        },
        high: {
          text: 'It looks like you have a HIGH level of risk aversion. This means that you are willing to pay more for a plan that offers more coverage.',
          header: 'High Risk Aversion',
        },
        medium: {
          text: 'It looks like you have a MEDIUM level of risk aversion. This means that you are looking for a balance between risk protection and savings.',
          header: 'Medium Risk Aversion',
        },
      },
    },
    incentives: {
      contentful_name: '[DEFAULT] Incentives Card',
      name: 'Incentives',
      tooltips: {
        name: {
          text: 'TODO - FIX THIS',
          title: 'TODO - FIX THIS',
          underline: 'TODO - FIX THIS',
        },
      },
      success_custom_messages: [
        {
          contentful_name: '[DEFAULT] Incentives Success Message',
          text: {
            data: {},
            content: [
              {
                data: {},
                content: [
                  {
                    data: {},
                    marks: [],
                    value:
                      'Based on your profile answers above, your employer offers the following incentives which apply to you. Please answer these additional questions to determine if you’re eligible.',
                    nodeType: 'text',
                  },
                ],
                nodeType: 'paragraph',
              },
            ],
            nodeType: 'document',
          },
          color: 'blue',
          icon: 'Info',
        },
        {
          contentful_name: '[DEFAULT] Incentives Profile Change Notice',
          text: {
            data: {},
            content: [
              {
                data: {},
                content: [
                  {
                    data: {},
                    marks: [],
                    value: 'Changing your profile answers above may cause these questions to reset.',
                    nodeType: 'text',
                  },
                ],
                nodeType: 'paragraph',
              },
            ],
            nodeType: 'document',
          },
          color: 'yellow',
          icon: 'Info',
        },
      ],
      empty_custom_messages: [
        {
          contentful_name: '[DEFAULT] No Incentives Eligible',
          text: {
            data: {},
            content: [
              {
                data: {},
                content: [
                  {
                    data: {},
                    marks: [],
                    value:
                      "Based on answers to the profile questions above, it doesn't look like you are eligible for any incentives at this time.",
                    nodeType: 'text',
                  },
                ],
                nodeType: 'paragraph',
              },
            ],
            nodeType: 'document',
          },
          color: 'yellow',
          icon: 'Info',
        },
      ],
      incentives: [
        {
          contentful_name: '[test-dan-1] Incentive - 10000',
          plan_id: '10000',
          title: 'HSA or HRA Incentive 1',
          question: 'How much will you contribute?',
          question_spouse: 'How much will you and your spouse contribute?',
          description: {
            data: {},
            content: [
              {
                data: {},
                content: [
                  {
                    data: {},
                    marks: [],
                    value:
                      'Single. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
                    nodeType: 'text',
                  },
                ],
                nodeType: 'paragraph',
              },
            ],
            nodeType: 'document',
          },
          description_spouse: {
            data: {},
            content: [
              {
                data: {},
                content: [
                  {
                    data: {},
                    marks: [],
                    value:
                      'With Spouse. Mi sit amet mauris commodo quis imperdiet massa. Elementum curabitur vitae nunc sed velit dignissim sodales. Elit sed vulputate mi sit amet mauris. Massa tincidunt nunc pulvinar sapien et ligula. Aliquam ut porttitor leo a.',
                    nodeType: 'text',
                  },
                ],
                nodeType: 'paragraph',
              },
            ],
            nodeType: 'document',
          },
        },
        {
          contentful_name: '[test-dan-1] Incentive - 12345',
          plan_id: '12345',
          title: 'HSA or HRA Incentive 2',
          question: 'Will you participate in this program?',
          question_spouse: 'Will you and your spouse participate in this program?',
          description: {
            data: {},
            content: [
              {
                data: {},
                content: [
                  {
                    data: {},
                    marks: [],
                    value:
                      'Single. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
                    nodeType: 'text',
                  },
                ],
                nodeType: 'paragraph',
              },
            ],
            nodeType: 'document',
          },
          description_spouse: {
            nodeType: 'document',
            data: {},
            content: [
              {
                nodeType: 'paragraph',
                data: {},
                content: [
                  {
                    nodeType: 'text',
                    value:
                      'With Spouse. Mi sit amet mauris commodo quis imperdiet massa. Elementum curabitur vitae nunc sed velit dignissim sodales. Elit sed vulputate mi sit amet mauris. Massa tincidunt nunc pulvinar sapien et ligula. Aliquam ut porttitor leo a.',
                    marks: [],
                    data: {},
                  },
                ],
              },
            ],
          },
        },
        {
          contentful_name: '[test-dan-1] Incentive - 67890',
          plan_id: '67890',
          title: 'HSA or HRA Incentive 3',
          question: 'Will you or your spouse participate in this program?',
          question_spouse: 'Will you or your spouse participate in this program?',
          description: {
            data: {},
            content: [
              {
                data: {},
                content: [
                  {
                    data: {},
                    marks: [],
                    value:
                      'Single. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
                    nodeType: 'text',
                  },
                ],
                nodeType: 'paragraph',
              },
            ],
            nodeType: 'document',
          },
          description_spouse: {
            nodeType: 'document',
            data: {},
            content: [
              {
                nodeType: 'paragraph',
                data: {},
                content: [
                  {
                    nodeType: 'text',
                    value:
                      'With Spouse. Mi sit amet mauris commodo quis imperdiet massa. Elementum curabitur vitae nunc sed velit dignissim sodales. Elit sed vulputate mi sit amet mauris. Massa tincidunt nunc pulvinar sapien et ligula. Aliquam ut porttitor leo a.',
                    marks: [],
                    data: {},
                  },
                ],
              },
            ],
          },
        },
      ],
    },
    income: {
      contentful_name: '[DEFAULT] Income Card',
      name: 'Income',
      tax_inputs_header: 'These next questions help us calculate tax benefits related to health insurance.',
      tooltips: {
        name: {
          text: 'Some of your benefits come with tax breaks. We’ll use your income to calculate the value of those tax breaks.',
          title: 'Income',
          underline: 'Income',
        },
      },
      income_header: 'What is the combined household income of your family?',
      income_header_policyholder: 'What is your annual income before taxes? (It’s OK to estimate here)',
      income_placeholder: '50,000',
      income_header_spouse: 'What is your spouse’s annual income before taxes?',
      income_placeholder_spouse: '50,000',
      filing_status_header: 'What is your tax filing status?',
      filing_status_options: {
        default: 'Select Filing Status',
        options: ['Single', 'Married', 'Married Filing Separately', 'Head of Household'],
      },
      skip_income_button: 'Prefer not to say',
    },
    capacity_to_pay: {
      contentful_name: '[DEFAULT] Capacity to Pay Card',
      name: 'Affordability',
      header:
        "If you need to receive unexpected medical care, what's the largest bill you can afford to pay?",
      button_options: {
        lt_500: 'Less than $500',
        gt_6000: '$6,000 or more',
        '500_1000': '$500 - $999',
        '1000_2000': '$1,000 - $1,999',
        '2000_3000': '$2,000 - $2,999',
        '3000_4000': '$3,000 - $3,999',
        '4000_6000': '$4,000 - $5,999',
      },
      tooltips: {
        name: {
          text: 'This is the capacity to pay tooltip',
          title: 'Affordability',
          underline: 'Affordability',
        },
      },
    },
    profile_complete_header: 'Profile Complete!',
    profile_complete_body:
      'Make sure your answers are accurate so that we can provide the best recommendations! Return to this page to make any profile updates.',
    accessibility: {
      instructions:
        'We see you are using a screen reader. As you answer questions, more content will become available.',
      unlocked_section: '{x} section unlocked',
    },
    domestic_partner_disclaimer: {},
    audio_configuration: {
      audio_configuration_title: 'Default Audio Configuration',
      profile_start_audio: {
        audio_moment_title: '',
        audio_file_url:
          'https://assets.ctfassets.net/z0ugtcgrmslx/7y6P3MDD5CgykXQcHqJ2rb/948a4f7a1b0a64ed2f64ddf7ac0b9b02/quite-well.wav',
        vtt_transcript_file_url:
          'https://assets.ctfassets.net/z0ugtcgrmslx/3s5afVjEt6K9aKlEJuQqKH/e2b5e915935eb25517c6620062d7729c/quite-well.vtt',
      },
      profile_complete_audio: {
        audio_moment_title: '',
        audio_file_url:
          'https://assets.ctfassets.net/z0ugtcgrmslx/7y6P3MDD5CgykXQcHqJ2rb/948a4f7a1b0a64ed2f64ddf7ac0b9b02/quite-well.wav',
        vtt_transcript_file_url:
          'https://assets.ctfassets.net/z0ugtcgrmslx/3s5afVjEt6K9aKlEJuQqKH/e2b5e915935eb25517c6620062d7729c/quite-well.vtt',
      },
      residence_section_audio: {
        audio_moment_title: '',
        audio_file_url:
          'https://assets.ctfassets.net/z0ugtcgrmslx/7y6P3MDD5CgykXQcHqJ2rb/948a4f7a1b0a64ed2f64ddf7ac0b9b02/quite-well.wav',
        vtt_transcript_file_url:
          'https://assets.ctfassets.net/z0ugtcgrmslx/3s5afVjEt6K9aKlEJuQqKH/e2b5e915935eb25517c6620062d7729c/quite-well.vtt',
      },
      household_section_audio: {
        audio_moment_title: '',
        audio_file_url:
          'https://assets.ctfassets.net/z0ugtcgrmslx/7y6P3MDD5CgykXQcHqJ2rb/948a4f7a1b0a64ed2f64ddf7ac0b9b02/quite-well.wav',
        vtt_transcript_file_url:
          'https://assets.ctfassets.net/z0ugtcgrmslx/3s5afVjEt6K9aKlEJuQqKH/e2b5e915935eb25517c6620062d7729c/quite-well.vtt',
      },
      income_section_audio: {
        audio_moment_title: '',
        audio_file_url:
          'https://assets.ctfassets.net/z0ugtcgrmslx/7y6P3MDD5CgykXQcHqJ2rb/948a4f7a1b0a64ed2f64ddf7ac0b9b02/quite-well.wav',
        vtt_transcript_file_url:
          'https://assets.ctfassets.net/z0ugtcgrmslx/3s5afVjEt6K9aKlEJuQqKH/e2b5e915935eb25517c6620062d7729c/quite-well.vtt',
      },
      risk_aversion_section_audio: {
        audio_moment_title: '',
        audio_file_url:
          'https://assets.ctfassets.net/z0ugtcgrmslx/7y6P3MDD5CgykXQcHqJ2rb/948a4f7a1b0a64ed2f64ddf7ac0b9b02/quite-well.wav',
        vtt_transcript_file_url:
          'https://assets.ctfassets.net/z0ugtcgrmslx/3s5afVjEt6K9aKlEJuQqKH/e2b5e915935eb25517c6620062d7729c/quite-well.vtt',
      },
      enrollment_section_audio_tooltip: {
        audio_moment_title: '',
        audio_file_url:
          'https://assets.ctfassets.net/z0ugtcgrmslx/7y6P3MDD5CgykXQcHqJ2rb/948a4f7a1b0a64ed2f64ddf7ac0b9b02/quite-well.wav',
        vtt_transcript_file_url:
          'https://assets.ctfassets.net/z0ugtcgrmslx/3s5afVjEt6K9aKlEJuQqKH/e2b5e915935eb25517c6620062d7729c/quite-well.vtt',
      },
      residence_section_audio_tooltip: {
        audio_moment_title: '',
        audio_file_url:
          'https://assets.ctfassets.net/z0ugtcgrmslx/7y6P3MDD5CgykXQcHqJ2rb/948a4f7a1b0a64ed2f64ddf7ac0b9b02/quite-well.wav',
        vtt_transcript_file_url:
          'https://assets.ctfassets.net/z0ugtcgrmslx/3s5afVjEt6K9aKlEJuQqKH/e2b5e915935eb25517c6620062d7729c/quite-well.vtt',
      },
      household_section_audio_tooltip: {
        audio_moment_title: '',
        audio_file_url:
          'https://assets.ctfassets.net/z0ugtcgrmslx/7y6P3MDD5CgykXQcHqJ2rb/948a4f7a1b0a64ed2f64ddf7ac0b9b02/quite-well.wav',
        vtt_transcript_file_url:
          'https://assets.ctfassets.net/z0ugtcgrmslx/3s5afVjEt6K9aKlEJuQqKH/e2b5e915935eb25517c6620062d7729c/quite-well.vtt',
      },
      sex_at_birth_audio_tooltip: {
        audio_moment_title: '',
        audio_file_url:
          'https://assets.ctfassets.net/z0ugtcgrmslx/7y6P3MDD5CgykXQcHqJ2rb/948a4f7a1b0a64ed2f64ddf7ac0b9b02/quite-well.wav',
        vtt_transcript_file_url:
          'https://assets.ctfassets.net/z0ugtcgrmslx/3s5afVjEt6K9aKlEJuQqKH/e2b5e915935eb25517c6620062d7729c/quite-well.vtt',
      },
      prescription_drugs_audio_tooltip: {
        audio_moment_title: '',
        audio_file_url:
          'https://assets.ctfassets.net/z0ugtcgrmslx/7y6P3MDD5CgykXQcHqJ2rb/948a4f7a1b0a64ed2f64ddf7ac0b9b02/quite-well.wav',
        vtt_transcript_file_url:
          'https://assets.ctfassets.net/z0ugtcgrmslx/3s5afVjEt6K9aKlEJuQqKH/e2b5e915935eb25517c6620062d7729c/quite-well.vtt',
      },
      utilization_section_audio_tooltip: {
        audio_moment_title: '',
        audio_file_url:
          'https://assets.ctfassets.net/z0ugtcgrmslx/7y6P3MDD5CgykXQcHqJ2rb/948a4f7a1b0a64ed2f64ddf7ac0b9b02/quite-well.wav',
        vtt_transcript_file_url:
          'https://assets.ctfassets.net/z0ugtcgrmslx/3s5afVjEt6K9aKlEJuQqKH/e2b5e915935eb25517c6620062d7729c/quite-well.vtt',
      },
      pcp_audio_tooltip: {
        audio_moment_title: '',
        audio_file_url:
          'https://assets.ctfassets.net/z0ugtcgrmslx/7y6P3MDD5CgykXQcHqJ2rb/948a4f7a1b0a64ed2f64ddf7ac0b9b02/quite-well.wav',
        vtt_transcript_file_url:
          'https://assets.ctfassets.net/z0ugtcgrmslx/3s5afVjEt6K9aKlEJuQqKH/e2b5e915935eb25517c6620062d7729c/quite-well.vtt',
      },
      specialist_audio_tooltip: {
        audio_moment_title: '',
        audio_file_url:
          'https://assets.ctfassets.net/z0ugtcgrmslx/7y6P3MDD5CgykXQcHqJ2rb/948a4f7a1b0a64ed2f64ddf7ac0b9b02/quite-well.wav',
        vtt_transcript_file_url:
          'https://assets.ctfassets.net/z0ugtcgrmslx/3s5afVjEt6K9aKlEJuQqKH/e2b5e915935eb25517c6620062d7729c/quite-well.vtt',
      },
      hospital_stays_audio_tooltip: {
        audio_moment_title: '',
        audio_file_url:
          'https://assets.ctfassets.net/z0ugtcgrmslx/7y6P3MDD5CgykXQcHqJ2rb/948a4f7a1b0a64ed2f64ddf7ac0b9b02/quite-well.wav',
        vtt_transcript_file_url:
          'https://assets.ctfassets.net/z0ugtcgrmslx/3s5afVjEt6K9aKlEJuQqKH/e2b5e915935eb25517c6620062d7729c/quite-well.vtt',
      },
      pregnancy_section_audio_tooltip: {
        audio_moment_title: '',
        audio_file_url:
          'https://assets.ctfassets.net/z0ugtcgrmslx/7y6P3MDD5CgykXQcHqJ2rb/948a4f7a1b0a64ed2f64ddf7ac0b9b02/quite-well.wav',
        vtt_transcript_file_url:
          'https://assets.ctfassets.net/z0ugtcgrmslx/3s5afVjEt6K9aKlEJuQqKH/e2b5e915935eb25517c6620062d7729c/quite-well.vtt',
      },
      income_section_audio_tooltip: {
        audio_moment_title: '',
        audio_file_url:
          'https://assets.ctfassets.net/z0ugtcgrmslx/7y6P3MDD5CgykXQcHqJ2rb/948a4f7a1b0a64ed2f64ddf7ac0b9b02/quite-well.wav',
        vtt_transcript_file_url:
          'https://assets.ctfassets.net/z0ugtcgrmslx/3s5afVjEt6K9aKlEJuQqKH/e2b5e915935eb25517c6620062d7729c/quite-well.vtt',
      },
      risk_section_audio_tooltip: {
        audio_moment_title: '',
        audio_file_url:
          'https://assets.ctfassets.net/z0ugtcgrmslx/7y6P3MDD5CgykXQcHqJ2rb/948a4f7a1b0a64ed2f64ddf7ac0b9b02/quite-well.wav',
        vtt_transcript_file_url:
          'https://assets.ctfassets.net/z0ugtcgrmslx/3s5afVjEt6K9aKlEJuQqKH/e2b5e915935eb25517c6620062d7729c/quite-well.vtt',
      },
      affordability_section_audio_tooltip: {
        audio_moment_title: '',
        audio_file_url:
          'https://assets.ctfassets.net/z0ugtcgrmslx/7y6P3MDD5CgykXQcHqJ2rb/948a4f7a1b0a64ed2f64ddf7ac0b9b02/quite-well.wav',
        vtt_transcript_file_url:
          'https://assets.ctfassets.net/z0ugtcgrmslx/3s5afVjEt6K9aKlEJuQqKH/e2b5e915935eb25517c6620062d7729c/quite-well.vtt',
      },
      planned_procedures_question_audio_tooltip: {
        audio_moment_title: '[Default] Planned Procedures Question',
        audio_file_url:
          'https://assets.ctfassets.net/z0ugtcgrmslx/3xbHI4BwRckidkg5OyxsLj/9ef58638020c9ccec4e35ebd0465d31a/Polished_Operations.wav',
        vtt_transcript_file_url:
          'https://assets.ctfassets.net/z0ugtcgrmslx/2GiXdymvL5JIJrw7panh9v/66e8e3fa500288f1d31f822061b8afd0/1_P.vtt',
      },
      planned_procedures_inpatient_audio_tooltip: {
        audio_moment_title: '[Default] Planned Procedures Inpatient Tooltip',
        audio_file_url:
          'https://assets.ctfassets.net/z0ugtcgrmslx/2ZEFMDun7NlhITOYc4w2sf/be740490eb1ccd24649905660ae342ce/Polished_Operations__1_.wav',
        vtt_transcript_file_url:
          'https://assets.ctfassets.net/z0ugtcgrmslx/3NGJVbGjPyUBU3SlEwUf9z/7f1715cf3bd035af2a546efd64ec874d/2_P.vtt',
      },
      planned_procedures_outpatient_audio_tooltip: {
        audio_moment_title: '[Default] Planned Procedures Outpatient Tooltip',
        audio_file_url:
          'https://assets.ctfassets.net/z0ugtcgrmslx/7JKIjDVMCOmqLzo86nqbdt/4f16f1f7d2061a087f5a286f663f22a9/Polished_Operations__2_.wav',
        vtt_transcript_file_url:
          'https://assets.ctfassets.net/z0ugtcgrmslx/jN5EccMBkI8dYx0w5iB5o/0e221efcde8c4a75cf589e2cf7292d7f/3_P.vtt',
      },
      planned_procedures_routine_therapy_audio_tooltip: {
        audio_moment_title: '[Default] Planned Procedures Therapy Tooltip',
        audio_file_url:
          'https://assets.ctfassets.net/z0ugtcgrmslx/5Umm9xPNlbxPX7oGyxak30/e71b2d08f41e742304c6d450e106c9b5/Polished_Operations__3_.wav',
        vtt_transcript_file_url:
          'https://assets.ctfassets.net/z0ugtcgrmslx/5g2VFqk8FVx6tlwEMyvWaF/444a2aaa0a7f8e6ac5b23be094b9997c/4_P.vtt',
      },
      planned_procedures_mental_health_audio_tooltip: {
        audio_moment_title: '[Default] Planned Procedures Mental Health Tooltip',
        audio_file_url:
          'https://assets.ctfassets.net/z0ugtcgrmslx/63O47rHjKfSQuJnzoG0qiO/b0e8812b34eb8ffdad38a0d8cfa62a9d/Polished_Operations__4_.wav',
        vtt_transcript_file_url:
          'https://assets.ctfassets.net/z0ugtcgrmslx/5cQ2HwxPkLbydBlw1dAU8B/ad66f4e4d2357f7d465b18d0f889df13/5_P.vtt',
      },
    },
  },
};
