// Actions
import { BuilderGoSettings } from 'Utils/apiTypes';

export const CHANGE_STEP = 'app/App/CHANGE_STEP';
export const LOAD_CONFIG = 'app/App/LOAD_CONFIG';
export const LOAD_CONFIG_SUCCESS = 'app/App/LOAD_CONFIG_SUCCESS';
export const LOAD_CONFIG_FAILURE = 'app/App/LOAD_CONFIG_FAILURE';
export const HANDLE_UNLOCK_MAIN_NAV = 'app/App/HANDLE_UNLOCK_MAIN_NAV';
export const HANDLE_UNLOCK_SUB_NAV = 'app/App/HANDLE_UNLOCK_SUB_NAV';
export const SET_PAGE_VIEWED = 'app/App/SET_PAGE_VIEWED';
export const CHOOSE_PRODUCT = 'app/App/CHOOSE_PRODUCT';
export const HANDLE_PROFILE_COMPLETE = 'app/App/HANDLE_PROFILE_COMPLETE';

// Page Slugs
export const PROFILE_SLUG = 'profile';
export const RESULT_SLUG = 'results';
export const INTRO_SLUG = 'intro';
export const VIEW_PLAN_SLUG = 'plan';
export const VIEW_BENEFIT_SLUG = 'benefit';
export const ERROR_SLUG = 'error';
export const OVERVIEW_SLUG = 'overview';
export const REVIEW_SLUG = 'review';
export const HSA_PLUS_SLUG = 'hsa';
export const TAX_SAVINGS_SLUG = 'tax-savings';
export const CONFIG_SLUG = 'config';

/**
 * Used by utils to strip page slugs from a URL so we can correctly
 * parse customer slug and segment slug if applicable.
 * If we add a new page, add the new slug as a constant and add it to this array
 */
export const PAGE_SLUGS = [
  INTRO_SLUG,
  PROFILE_SLUG,
  RESULT_SLUG,
  HSA_PLUS_SLUG,
  TAX_SAVINGS_SLUG,
  VIEW_PLAN_SLUG,
  OVERVIEW_SLUG,
  VIEW_BENEFIT_SLUG,
  REVIEW_SLUG,
  CONFIG_SLUG,
  ERROR_SLUG,
];

export enum COVERAGE_TIERS {
  INDIVIDUAL = 'individual',
  INDIVIDUAL_SPOUSE = 'individual_spouse',
  INDIVIDUAL_CHILD = 'individual_child',
  INDIVIDUAL_CHILDREN = 'individual_children',
  FAMILY = 'family',
}

// Tiers
export const INDIVIDUAL_TIER = COVERAGE_TIERS.INDIVIDUAL;
export const COUPLE_TIER = COVERAGE_TIERS.INDIVIDUAL_SPOUSE;
export const INDIVIDUAL_DEPENDANT_TIER = COVERAGE_TIERS.INDIVIDUAL_CHILD;
export const FAMILY_TIER = COVERAGE_TIERS.FAMILY;

// Low Population ZIP3
export const LOW_POP_ZIP3 = [
  '036',
  '059',
  '063',
  '102',
  '203',
  '556',
  '692',
  '790',
  '821',
  '823',
  '830',
  '831',
  '878',
  '879',
  '884',
  '890',
  '893',
];

// Filing statuses
export const SINGLE = 'single';
export const MARRIED = 'married';
export const MARRIED_FILING_SEPARATELY = 'married_separately';
export const HEAD_OF_HOUSEHOLD = 'head_of_household';

// JVP Actions

// Bootstrap
export const GET_JVP_BOOTSTRAP = 'app/JVP/GET_JVP_BOOTSTRAP';
export const GET_JVP_BOOTSTRAP_SUCCESS = 'app/JVP/GET_JVP_BOOTSTRAP_SUCCESS';
export const GET_JVP_BOOTSTRAP_FAILURE = 'app/JVP/GET_JVP_BOOTSTRAP_FAILURE';

// JVP User
export const GET_JVP_USER_SUCCESS = 'app/JVP/GET_JVP_USER_SUCCESS';
export const GET_JVP_USER_FAILURE = 'app/JVP/GET_JVP_USER_FAILURE';

export const SET_ENROLLMENT_EVENT_TYPE = 'app/JVP/SET_ENROLLMENT_EVENT_TYPE';
export const SET_ENROLLMENT_EVENT_TYPE_SUCCESS = 'app/JVP/SET_ENROLLMENT_EVENT_TYPE_SUCCESS';
export const SET_ENROLLMENT_EVENT_TYPE_FAILURE = 'app/JVP/SET_ENROLLMENT_EVENT_TYPE_FAILURE';

export const SET_ENROLLMENT_EVENT_PROFILE_COMPLETE = 'app/JVP/SET_ENROLLMENT_EVENT_PROFILE_COMPLETE';
export const SET_ENROLLMENT_EVENT_PROFILE_COMPLETE_SUCCESS =
  'app/JVP/SET_ENROLLMENT_EVENT_PROFILE_COMPLETE_SUCCESS';
export const SET_ENROLLMENT_EVENT_PROFILE_COMPLETE_FAILURE =
  'app/JVP/SET_ENROLLMENT_EVENT_PROFILE_COMPLETE_FAILURE';

export const GO_GUEST_ID = 'go_guest_id';
export const GO_ENROLLMENT_EVENT_ID = 'go_enrollment_event_id';

export const CONFIG_POLLING_INTERVAL_MS = 1000 * 60;

export const DEFAULT_IDLE_TIME_LENGTH = 30 * 1000 * 60;
export const IDLE_TIME_LENGTH_FOR_STAGING = 1 * 1000 * 60;

// Integrated User Session Management
export const JVP_SESSION_PULSE_TIME = 4 * 1000 * 60;
export const SET_JVP_SESSION_ID = 'app/JVP/SET_JVP_SESSION_ID';
export const PULSE_JVP_SESSION = 'app/JVP/PULSE_JVP_SESSION';
export const GET_AAPI_USER_REQUEST = 'app/AAPI/GET_AAPI_USER_REQUEST';
export const GET_AAPI_USER_SUCCESS = 'app/AAPI/GET_AAPI_USER_SUCCESS';
export const GET_AAPI_USER_FAILURE = 'app/AAPI/GET_AAPI_USER_FAILURE';

export const SET_ALEX_ID_UUID = 'app/SET_ALEX_ID_UUID';

export const SET_DEMO_MODE = 'app/SET_DEMO_MODE';
export const SET_UNVALIDATED_SEGMENT_SLUG = 'app/SET_UNVALIDATED_SEGMENT_SLUG';
export const SET_BC_URL_HASH = 'app/SET_BC_URL_HASH';
export const SET_VALIDATED_SEGMENT_SLUG = 'app/SET_VALIDATED_SEGMENT_SLUG';

// Returning user management
export const UPDATE_PREVIOUS_SELECTED_PLAN = 'app/UPDATE_PREVIOUS_SELECTED_PLAN';

export const DEFAULT_BUILDER_GO_SETTINGS: BuilderGoSettings = {
  alex_go_language: 'english',
  show_accident_video: true,
  show_commuter_video: true,
  show_dcfsa_video: true,
  show_dental_video: true,
  show_eap_video: true,
  show_financial_wellness_video: true,
  show_fsa_video: true,
  show_hospital_indemnity_video: true,
  show_hra_video: true,
  show_hsa_video: true,
  show_legal_video: true,
  show_lpfsa_video: true,
  show_ltd_video: true,
  show_retirement_video: true,
  show_std_video: true,
  show_telemedicine_video: true,
  show_vision_video: true,
  show_wellness_video: true,
  show_add_video: true,
  show_critical_illness_video: true,
  show_group_life_video: true,
};
