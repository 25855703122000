import { Flex, Stack, useDisclosure } from '@chakra-ui/react';
import { isEmpty } from 'lodash';
import React from 'react';

import { TextField } from 'Containers/App/types';
import { EnteredPlan } from 'Containers/CommercialRoutes/types';
import PlanEntryModal from 'Containers/ResultPage/PlanEntryModal';
import { useCompareContext } from 'Contexts/compareContext';
import { Button, FadeUp, H2, Paragraph } from 'DesignLibrary/atoms';
import { PicwellScore } from 'DesignLibrary/atoms/PicwellScore';
import { SelectedBadge } from 'DesignLibrary/atoms/SelectedBadge';
import { useViewport } from 'DesignLibrary/context';
import { PlanFit } from 'Shared/PlanFit';
import Text from 'Shared/Text';
import { Recommendation } from 'Types/entities';
import { PeopleLikeYou } from 'Utils/apiTypes';
import { sendInsightsEvent } from 'Utils/insights';

import { Head, HeaderContainer } from './styled';

function userHasSelectedAPlan(
  selectedPlan: Recommendation | Record<string, never>,
): selectedPlan is Recommendation {
  return !isEmpty(selectedPlan);
}
export interface ViewPlanPageHeaderProps {
  recommendation: Recommendation;
  selectedPlan: Recommendation | Record<string, never>;
  maximumAvailableAnnualSihraContribution: number;
  displayEditPlanButton: boolean;
  displayPdfButton: boolean;
  pdfButtonLink: string;
  displaySearchProviderButton: boolean;
  isImprovedRecExplanationEnabled: boolean;
  searchProviderButtonTextField: TextField;
  searchProviderButtonLink: string;
  individualHsaContributionLimit: number;
  familyHsaContributionLimit: number;
  enteredPlan: EnteredPlan;
  isFamily: boolean;
  hidePlanScore: boolean;
  plyData: PeopleLikeYou | Record<string, never>;
  handleEnroll: () => void;
  handleDeselect: () => void;
  getRecommendationsWithEphemeralPlan: (EnteredPlan: EnteredPlan) => void;
  saveEphemeralPlan: (plan: EnteredPlan) => void;
  goToPreviousPage: () => void;
}

export const ViewPlanPageHeader = ({
  recommendation,
  selectedPlan,
  maximumAvailableAnnualSihraContribution,
  displayEditPlanButton,
  displayPdfButton,
  pdfButtonLink,
  displaySearchProviderButton,
  isImprovedRecExplanationEnabled,
  searchProviderButtonTextField,
  searchProviderButtonLink,
  individualHsaContributionLimit,
  familyHsaContributionLimit,
  enteredPlan,
  isFamily,
  hidePlanScore,
  plyData,
  handleEnroll,
  handleDeselect,
  getRecommendationsWithEphemeralPlan,
  saveEphemeralPlan,
  goToPreviousPage,
}: ViewPlanPageHeaderProps) => {
  const { device } = useViewport();
  const { isOpen: planEntryIsOpen, onOpen: planEntryOnOpen, onClose: planEntryOnClose } = useDisclosure();
  const { resetComparedPlans } = useCompareContext();

  // TODO: Cleanup plan entry code, move insights into PlanEntryModal
  const handleOpenPlanEntry = () => {
    sendInsightsEvent(null, 'info_modal_view_started', {
      modal_id: 'plan_entry_modal',
    });
    planEntryOnOpen();
  };

  const handleClosePlanEntry = () => {
    sendInsightsEvent(null, 'info_modal_view_ended', {
      modal_id: 'plan_entry_modal',
    });
    planEntryOnClose();
  };

  const handleEphemeralPlanRecommendation = (plan) => {
    resetComparedPlans();
    getRecommendationsWithEphemeralPlan(plan);
    goToPreviousPage();
  };

  // TODO: Use Plan Model here instead of raw recommendation response
  const score = recommendation.score;
  const tier = recommendation.tier;
  const { carrier_name, plan_marketing_name } = recommendation.plan;

  const isSelectedPlan = userHasSelectedAPlan(selectedPlan)
    ? selectedPlan.plan.external_id === recommendation.plan.external_id
    : false;

  const isDesktop = device === 'desktop';
  const isMobile = device === 'mobile';

  return (
    <HeaderContainer isSelected={isSelectedPlan}>
      <div className="score-container-attached">
        {!hidePlanScore && !isImprovedRecExplanationEnabled && (
          <FadeUp
            translateX="1rem"
            translateY="0"
            timeout={750}
            delay={2000}
            isOpen={isDesktop && !isSelectedPlan}
          >
            <PicwellScore size="large" score={score} tier={tier} attached />
          </FadeUp>
        )}
      </div>
      <div className="badge-container">
        <div className={`badge ${isImprovedRecExplanationEnabled ? 'lower' : ''} `}>
          <FadeUp translateX="1rem" translateY="0" timeout={500} isOpen={isDesktop && isSelectedPlan}>
            <SelectedBadge />
          </FadeUp>
        </div>
      </div>
      {!hidePlanScore && isImprovedRecExplanationEnabled && <PlanFit score={score} plyData={plyData} />}

      <Stack p={[4, 6]} gap={4} pos="relative">
        <Head>
          <div className="score-container">
            <FadeUp isOpen={!isDesktop && isSelectedPlan}>
              <SelectedBadge sizeOverride={isMobile ? 'small' : 'large'} />
            </FadeUp>
            {!hidePlanScore && !isImprovedRecExplanationEnabled && (
              <FadeUp isOpen={!isDesktop && !isSelectedPlan}>
                <PicwellScore size={isMobile ? 'medium' : 'large'} score={score} tier={tier} />
              </FadeUp>
            )}
          </div>
          <div className={`header-content ${isSelectedPlan ? 'scoot' : null}`}>
            <Flex align="center" columnGap={[2, 3]} rowGap={0} mb={2} flexWrap="wrap">
              <Paragraph lineHeight={1.35} size="normal">
                {carrier_name}
              </Paragraph>
            </Flex>
            <H2 as="h1" lineHeight={1.15}>
              {plan_marketing_name}
            </H2>
          </div>
        </Head>

        <Stack gap={1} direction={device !== 'tablet' ? 'column' : 'row'}>
          <Button
            buttonType={isSelectedPlan ? 'secondary' : 'primary'}
            onClick={isSelectedPlan ? handleDeselect : handleEnroll}
            iconRight={!isSelectedPlan ? 'PlusCircle' : 'X'}
            stretch
            justify="space-between"
          >
            {isSelectedPlan ? (
              <Text field="health_section.button_text.deselectPlan" />
            ) : (
              <Text field="health_section.button_text.choosePlan" />
            )}
          </Button>

          {displayPdfButton && (
            <Button
              buttonType="secondary"
              url={pdfButtonLink}
              iconRight="ArrowUpRight"
              stretch
              justify="space-between"
              isDisabled={pdfButtonLink === ''}
              data-testid="plan-pdf-button-header"
            >
              <Text field="health_section.button_text.planPdf" />
            </Button>
          )}
          {displaySearchProviderButton && (
            <Button
              buttonType="secondary"
              isDisabled={searchProviderButtonLink === ''}
              url={searchProviderButtonLink}
              iconRight="ArrowUpRight"
              stretch
              justify="space-between"
              data-testid="search-provider-button-header"
            >
              <Text field={searchProviderButtonTextField} />
            </Button>
          )}
          {displayEditPlanButton && (
            <Button
              buttonType="secondary"
              onClick={handleOpenPlanEntry}
              iconRight="Pencil"
              justify="space-between"
              stretch
            >
              <Text field="spousal_plan_comparison.button_text.editModal" />
            </Button>
          )}
        </Stack>
      </Stack>
      {displayEditPlanButton && (
        <PlanEntryModal
          handleClose={handleClosePlanEntry}
          isOpen={planEntryIsOpen}
          getRecommendations={(plan) => handleEphemeralPlanRecommendation(plan)}
          plan={enteredPlan}
          savePlan={saveEphemeralPlan}
          individualHsaContributionLimit={individualHsaContributionLimit}
          familyHsaContributionLimit={familyHsaContributionLimit}
          isFamily={isFamily}
          maximumAvailableAnnualSihraContribution={maximumAvailableAnnualSihraContribution}
        />
      )}
    </HeaderContainer>
  );
};
